export const INDEX_PATH = '/';
export const STAKING_PATH = `${INDEX_PATH}staking`;
export const CLAIMING_PATH = `${INDEX_PATH}claiming`;
export const LOANS_PATH = `${INDEX_PATH}loans`;
export const LENDING_PATH = `${INDEX_PATH}zenterest`;
export const LENDING_PRICES_PATH = `${INDEX_PATH}zenterest/prices`;
export const LENDING_MARKETS_PATH = `${INDEX_PATH}zenterest/markets`;
export const LENDING_PAUSED_MARKETS_PATH = `${INDEX_PATH}zenterest/paused-markets`;
export const LENDING_LIQUIDATION_PATH = `${INDEX_PATH}zenterest/liquidation`;
export const GOVERNANCE_PATH = `${INDEX_PATH}governance`;
export const MANTRA_POOL_PATH = `${INDEX_PATH}mantra-pool`;
export const SUPPORT_PATH = `${INDEX_PATH}support`;
export const OM_TOKEN_SWAP_PATH = `${INDEX_PATH}om-token-swap`;
export const DELEGATOR_PATH = `${INDEX_PATH}delegator`;

export const ACCOUNT_ROUTES = [STAKING_PATH, OM_TOKEN_SWAP_PATH];

export const ALL_TOKENS_PATH = `${STAKING_PATH}/all`;
export const STAKE_TOKENS_PATH = `${STAKING_PATH}/stake`;
export const NOMINATE_TOKENS_PATH = `${STAKING_PATH}/nominate`;

export const CHAINS_PATHS = [STAKING_PATH, ALL_TOKENS_PATH, NOMINATE_TOKENS_PATH];
export const TOKENS_PATHS = [STAKING_PATH, ALL_TOKENS_PATH, STAKE_TOKENS_PATH];
