import { createTheme } from '@material-ui/core/styles';
import LIGHT_UNICORN_ICON from './assets/images/currencies/light-unicorn-icon.svg';
import DARK_UNICORN_ICON from './assets/images/currencies/unicorn-icon.svg';
import {
  LENDING_MARKETS_PATH,
  LENDING_PATH,
  LENDING_PRICES_PATH,
} from './constants/router-constants';

const GRAY_BACKGROUND_PAGES = [LENDING_PATH, LENDING_PRICES_PATH, LENDING_MARKETS_PATH];

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiTab: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: '10px',
        backgroundColor: '#7F89A7',
        fontSize: '15px',
        lineHeight: '18px',
        borderRadius: '7px',
      },
    },
  },
  palette: {
    light: {
      text: '#201f1e',
      background: GRAY_BACKGROUND_PAGES.includes(window.location.pathname) ? '#F9FAFF' : '#ffffff',
      stakingFrameBackground: '#ffffff',
      cardHeader: '#282626',
      cardBackground: '#F1F3F8',
      cardBackgroundTint: '#F1F3F8',
      listAssetBackground: 'white',
      inputBackground: 'white',
      boringGrey: '#8089A4',
      banner: '#201f1e',
      divider: '#F2F1F1',
      icons: {
        unicorn: DARK_UNICORN_ICON,
      },
    },
    dark: {
      text: '#FFFFFF',
      // background: '#282626',
      background: '#282626',
      cardHeader: '#171616',
      cardBackground: '#10131C',
      cardBackgroundTint: '#171616',
      inputBackground: '#535F7C',
      stakingFrameBackground: '#302e2d',
      listAssetBackground: '#302e2d',
      boringGrey: '#42495A',
      banner: '#171616',
      divider: '#3A4154',
      icons: {
        unicorn: LIGHT_UNICORN_ICON,
      },
    },
    mantra: {
      pink: '#630e17',
      moneyGreen: '#76B770',
    },
    primary: {
      main: '#630e17',
    },
    secondary: {
      main: '#630e17',
    },
  },
});

export default theme;
