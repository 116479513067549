import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Account from '../account';
import styles from './header.module.scss';
import networkConstant from '../../constants/network.constant';
import BinanceLogo from '../../external/components/Icon/binance';
import PolygonLogo from '../../external/components/Icon/polygon';
import { BINANCE, POLYGON } from '../../constants/blockchain-constants';
import LogoMilc from '../../assets/images/logo.5fe8a857.png';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';
import Menu from '../../assets/images/menu.svg';

const Header = ({ children }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { networkId, networkType, auth } = useSelector((state) => state.account);

  const productionNetworks = [
    networkConstant.networkId.ethMainnet,
    networkConstant.networkId.bscMainnet,
    networkConstant.networkId.polyMainnet,
  ];

  const isProd = productionNetworks.includes(networkId);

  const isMetamaskInstalled = Boolean(window.ethereum && window.ethereum.isMetaMask);
  const mobile = useMediaQuery('(max-width: 767px)');
  const toggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
  };
  const wrapStylesHeader = {
    position: 'fixed',
    height: mobile ? '158px' : '104px',
    zIndex: '5',
    backgroundColor: '#0e0e1775',
    width: '100%',
    justifyContent: 'center',
    backdropFilter: 'blur(4px)',
  };
  const wrapStyles = {
    display: 'flex',
    justifyContent: mobile ? 'center' : 'space-between',
    height: mobile ? '158px' : '104px',
    alignItems: mobile ? 'center' : 'center',
    zIndex: '5',
    width: '100%',
    maxWidth: 1440,
    paddingTop: mobile ? '10px' : '20px',
    paddingBottom: mobile ? '10px' : '20px',
    paddingRight: mobile ? '2.5rem' : '2.5rem',
    paddingLeft: mobile ? '2.5rem' : '2.5rem',
    gap: mobile ? '10px' : '0',
  };
  const BscButton = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    color: #24191e;
    border-radius: 6px;
    height: 37px;
    padding: 13px;
    border: none;
    filter: drop-shadow(0px 4px 0px rgba(0, 0, 0, 0.25));
    margin-top: 0.5rem;

    @media screen and (min-width: 768px) {
      margin-left: 2rem;
      margin-top: 0;
    }

    @media screen and (min-width: 992px) {
    }

    &:hover {
      text-decoration: underline;
    }

    &:disabled {
      background-color: #919191;
      text-decoration: none;
      cursor: inherit;
    }
  `;

  const BscLogo = styled(BinanceLogo)`
    width: 24px;
    height: 24px;
    margin-left: 1rem;
  `;

  const PolyLogo = styled(PolygonLogo)`
    width: 24px;
    height: 24px;
    margin-left: 1rem;
  `;

  const getNetworkConnectParams = (isProd) => {
    isProd = true;
    return {
      [BINANCE]: [
        {
          chainId: isProd
            ? networkConstant.networkIdHex.bscMainnet
            : networkConstant.networkIdHex.bscTestnet,
          chainName: `Binance Smart Chain${isProd ? '' : ' (Testnet)'}`,
          nativeCurrency: {
            name: 'Binance Token',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          ...(isProd && { blockExplorerUrls: ['https://bscscan.com/'] }),
          ...(!isProd && { blockExplorerUrls: ['https://testnet.bscscan.com/'] }),
        },
      ],
      [POLYGON]: [
        {
          chainId: isProd
            ? networkConstant.networkIdHex.polyMainnet
            : networkConstant.networkIdHex.polyTestnet,
          chainName: `Polygon${isProd ? '' : ' (Testnet)'}`,
          nativeCurrency: {
            name: 'MATIC',
            symbol: 'MATIC',
            decimals: 18,
          },
          rpcUrls: ['https://polygon-rpc.com'],
          ...(isProd && { blockExplorerUrls: ['https://polygonscan.com/'] }),
          ...(!isProd && { blockExplorerUrls: ['https://mumbai.polygonscan.com/'] }),
        },
      ],
    };
  };
  async function handleNetworkChange(network) {
    if (auth === 'wallet_connect') {
      // update wallet connect to use bsc
    } else if (auth === 'metamask') {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: getNetworkConnectParams(isProd)[network][0].chainId }],
        });
      } catch (error) {
        if (error?.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: getNetworkConnectParams(isProd)[network],
            });
          } catch (error) {
            return;
          }
        }
      }
    }
  }

  return (
    <>
      <div className={styles.accountContainer} style={{ ...wrapStylesHeader }}>
        <Grid style={{ justifyContent: 'center', alignItems: 'center' }} container>
          {isMetamaskInstalled && networkId && networkType && auth ? (
            <img
              alt='Menu'
              width={32}
              className={styles.drawerBtn2}
              onClick={toggleDrawer}
              src={Menu}
            ></img>
          ) : null}

          <Grid style={{ ...wrapStyles }} item>
            {children}
            {isMetamaskInstalled && networkId && networkType && auth ? (
              <img
                alt='Menu'
                width={32}
                className={styles.drawerBtn}
                onClick={toggleDrawer}
                src={Menu}
              ></img>
            ) : null}
            <a
              className={styles.accountContainer}
              style={{
                textDecoration: 'none',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              href='https://project.milc.global/'
              target='_blank'
              rel='noreferrer'
            >
              <img alt='MILC' width={70} src={LogoMilc}></img>
              <h1
                style={{
                  fontSize: 14,
                  fontFamily: 'Neuropol',
                  letterSpacing: -0.5,
                  margin: 0,
                  color: '#fff',
                  textDecoration: 'none',
                }}
              >
                MILC
              </h1>
            </a>

            <div className={styles.hasMeta}>
              <Account />
              {isMetamaskInstalled &&
              networkId &&
              networkType &&
              auth &&
              networkType !== networkConstant.networkType.binance &&
              auth !== 'wallet_connect_bsc' ? (
                <>
                  <BscButton
                    onClick={() => handleNetworkChange(BINANCE)}
                    className={styles.bscBtns}
                    disabled={
                      networkType === networkConstant.networkType.binance ||
                      auth === 'wallet_connect_bsc'
                    }
                  >
                    Change to BSC
                    <BscLogo />
                  </BscButton>
                  {/*<BscButton
                  onClick={() => handleNetworkChange(POLYGON)}
                  className={styles.bscBtns}
                  disabled={
                    networkType === networkConstant.networkType.polygon ||
                    auth === 'wallet_connect_polygon'
                  }
                >
                  Change to Polygon
                  <PolyLogo />
                </BscButton>*/}
                </>
              ) : null}
            </div>
          </Grid>
        </Grid>
      </div>
      {isMetamaskInstalled && networkId && networkType && auth ? (
        <Drawer anchor={'left'} open={isOpenDrawer} onClose={toggleDrawer}>
          <div className={styles.mainDrawer}>
            <div>
              <>
                <BscButton
                  onClick={() => {
                    handleNetworkChange(BINANCE);
                    toggleDrawer();
                  }}
                  className={styles.drawerItem}
                  disabled={
                    networkType === networkConstant.networkType.binance ||
                    auth === 'wallet_connect_bsc'
                  }
                >
                  Change to BSC
                  <BscLogo />
                </BscButton>
                <BscButton
                  onClick={() => {
                    handleNetworkChange(POLYGON);
                    toggleDrawer();
                  }}
                  className={styles.drawerItem}
                  disabled={
                    networkType === networkConstant.networkType.polygon ||
                    auth === 'wallet_connect_polygon'
                  }
                >
                  Change to Polygon
                  <PolyLogo />
                </BscButton>
              </>
            </div>
          </div>
        </Drawer>
      ) : null}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

Header.defaultProps = {
  children: null,
};

export default Header;
