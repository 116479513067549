import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { CLOSED_POOLS_ETH } from './ethPoolsClosed';
import { StakingPool } from './staking.types';

const MLT_ETH_POOL: StakingPool = {
  networkId: networkConstant.networkId.ethSepolia,
  token0Symbol: 'MLT',
  token1Symbol: 'ETH',
  stakingTokenSymbol: 'MLT/ETH' as ContractType,
  stakingTokenAddress: '0x36b3A7D16eEFda3346927106cdE29AAcfb89ea89',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'MLT',
  rewardTokenAddress: '0x5831778dFF002c4808c62f541Fa8d61D0bBc3F18',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xe04cd0ef09d3d0aD7a636f61EA74ac2116bBB13b',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
};

export const ETH_POOLS: StakingPool[] = [
  MLT_ETH_POOL,
  ...CLOSED_POOLS_ETH,
  // RFUEL_POOL_CLOSED,
];
