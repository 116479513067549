import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { StakingPool } from './staking.types';

const L3P_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'L3P',
  stakingContractAddress: '0xdbc34d084393ed8d7b750FfCCea5A139EC7b9349',
  stakingTokenAddress: '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const BCUBE_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'BCUBE',
  stakingContractAddress: '0xb19b94d53D362CDfC7360C951a85ca2c1d5400BA',
  stakingTokenAddress: '0x93C9175E26F57d2888c7Df8B470C9eeA5C0b0A93',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const BITE_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'BITE',
  stakingContractAddress: '0xa571309B1267676568Bf9f155606a08790896Fe2',
  stakingTokenAddress: '0x4eED0fa8dE12D5a86517f214C2f11586Ba2ED88D',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const SKYRIM_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'SKYRIM',
  stakingContractAddress: '0x93666797a2AE5cc8876D9Bc00A67ECDCc6e7A4Fa',
  stakingTokenAddress: '0x2610f0bfc21ef389fe4d03cfb7de9ac1e6c99d6e',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const RFUEL_POOL_CLOSED_V2: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'RFUEL',
  stakingContractAddress: '0x456DF576962289256A92290C9E48EE116B8Cb413',
  stakingTokenAddress: '0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const RAZE_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'RAZE',
  stakingContractAddress: '0x2D0eA72Db9f9A63F4B185EAB1ca74137d808eBfa',
  stakingTokenAddress: '0x5Eaa69B29f99C84Fe5dE8200340b4e9b4Ab38EaC',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const ROYA_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'ROYA',
  stakingContractAddress: '0x4Cd4c0eEDb2bC21f4e280d0Fe4C45B17430F94A9',
  stakingTokenAddress: '0x7eaf9c89037e4814dc0d9952ac7f888c784548db',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const FXF_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'FXF',
  stakingContractAddress: '0x6BcDC61A7A6d86f7b7B66d461b7eF7fa268571a0',
  stakingTokenAddress: '0x8a40c222996f9F3431f63Bf80244C36822060f12',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
  finished: true,
};

const OM_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'OM',
  stakingContractAddress: '0x9e15ad979919bb4db331bfe864475ae3bffeba93',
  stakingTokenAddress: '0x3593D125a4f7849a1B059E64F4517A86Dd60c95d',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const KYL_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'KYL',
  stakingContractAddress: '0x6Ae05B5dB520011bf76645EBB4d6A697e5B3774b',
  stakingTokenAddress: '0x3593D125a4f7849a1B059E64F4517A86Dd60c95d',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const PKF_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'PKF',
  stakingContractAddress: '0x1dFdb0fb85402dC7f8D72d92ADa8FBBB3ffc8633',
  stakingTokenAddress: '0x8B39B70E39Aa811b69365398e0aACe9bee238AEb',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const BCUBE_ETH_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'BCUBE',
  token1Symbol: 'WETH',
  stakingTokenSymbol: 'BCUBE/ETH' as ContractType,
  stakingTokenAddress: '0xc62bf2c79f34ff24e2f97982af4f064161ed8949',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BCUBE',
  rewardTokenAddress: '0x93c9175e26f57d2888c7df8b470c9eea5c0b0a93',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xFF964d0bf9f81c401932A6B975EAE54129712eE5',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

const ZEN_UST_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'OM' as ContractType, // hack
  token1Symbol: 'WETH' as ContractType, // hack
  stakingTokenSymbol: 'zenUST',
  stakingTokenAddress: '0xab576bcbb0c3303c9e680fbfdeca67e062eae59c',
  stakingTokenDecimals: 8,
  rewardTokenSymbol: 'OM',
  rewardTokenAddress: '0x3593d125a4f7849a1b059e64f4517a86dd60c95d',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x2fd419DAC5dCF9470F391Ee9236D244A9db4Fa2e',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  isZen: true,
  finished: true,
};

const UNI_LABS_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'LABS' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'LABS/ETH' as ContractType,
  stakingTokenAddress: '0x2D9FD51E896Ff0352Cb6D697D13D04C2CB85CA83',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'LABS',
  rewardTokenAddress: '0x8b0e42f366ba502d787bb134478adfae966c8798',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x5f81a986611C600a3656d9adc202283186C6121D',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  finished: true,
  flipReserves: true,
};

const UNI_ROYA_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'ROYA' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'ROYA/ETH' as ContractType,
  stakingTokenAddress: '0x2D9FD51E896Ff0352Cb6D697D13D04C2CB85CA83',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'ROYA',
  rewardTokenAddress: '0x7eaf9c89037e4814dc0d9952ac7f888c784548db',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x55e0F2cE66Fa8C86ef478fa47bA0bE978eFC2647',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  finished: true,
  flipReserves: true,
};

const UNI_LABS_CLOSED_TOLLBOOTH: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'LABS' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'LABS/ETH (TB)' as ContractType,
  stakingTokenAddress: '0x2D9FD51E896Ff0352Cb6D697D13D04C2CB85CA83',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'LABS',
  rewardTokenAddress: '0x8b0E42F366bA502d787BB134478aDfAE966C8798',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xFc8e3B55897d8cEf791451bbE69B204B9C58Fc8a',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  finished: true,
  flipReserves: true,
};

const OM_V1_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'OM' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'OMV1/ETH' as ContractType,
  stakingTokenAddress: '0x2D9FD51E896Ff0352Cb6D697D13D04C2CB85CA83',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'OM/V1',
  rewardTokenAddress: '0x2baecdf43734f22fd5c152db08e3c27233f0c7d2',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x659236870915601d8B581e4355BD822483Fe5739',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  finished: true,
  flipReserves: true,
};

const BITE_ETH_LP_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'BITE' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'BITE/ETH (TB)' as ContractType,
  stakingTokenAddress: '0x1f07f8E712659087914B96Db4d6f6e4fee32285e',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BITE',
  rewardTokenAddress: '0x4eed0fa8de12d5a86517f214c2f11586ba2ed88d',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x18Ba986ED3128fc7E3E86a09E902436e900a899c',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'BLOCK',
  finished: true,
  flipReserves: true,
};

const BONDLY_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'BONDLY',
  stakingContractAddress: '0x39621a555554a7ff77f2b64185c53e04c90cd540',
  stakingTokenAddress: '0xd2dda223b2617cb616c1580db421e4cfae6a8a85',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const IMPACT_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'IMPACT',
  stakingContractAddress: '0x6DdF7743f56Efa60a4834AFEd16B2dc13308f13e',
  stakingTokenAddress: '0xFAc3f6391C86004289A186Ae0198180fCB4D49Ab',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const LABS_POOL_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  stakingTokenSymbol: 'LABS',
  stakingContractAddress: '0xb96e42c0de658ca26048b0e200f9a1e05ad89e0f',
  stakingTokenAddress: '0x8b0e42f366ba502d787bb134478adfae966c8798',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: '0',
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'SECOND',
  finished: true,
};

const UNI_BBANK_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'WETH' as ContractType,
  token1Symbol: 'BBANK' as ContractType,
  stakingTokenSymbol: 'BBANK/ETH' as ContractType,
  stakingTokenAddress: '0x1f07f8E712659087914B96Db4d6f6e4fee32285e',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BBANK',
  rewardTokenAddress: '0xf4b5470523ccd314c6b9da041076e7d79e0df267',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x6406788d1CD4fdD823ef607A924c00a4244a841d',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const UNI_BONDLY_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'WETH' as ContractType,
  token1Symbol: 'BONDLY' as ContractType,
  stakingTokenSymbol: 'BONDLY/ETH' as ContractType,
  stakingTokenAddress: '0x9dc696f1067a6B9929986283f6D316Be9C9198Fd',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BONDLY',
  rewardTokenAddress: '0xD2dDa223b2617cB616c1580db421e4cFAe6a8a85',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x4d081f600b480b0ce8b422fba3a5ea1fb4b36b3b',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
};

const UNI_BONDLY_USDT_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'BONDLY' as ContractType,
  token1Symbol: 'USDT' as ContractType,
  stakingTokenSymbol: 'BONDLY/USDT' as ContractType,
  stakingTokenAddress: '0xdc43e671428b4e7b7848ea92cd8691ac1b80903c',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BONDLY',
  rewardTokenAddress: '0xd2dda223b2617cb616c1580db421e4cfae6a8a85',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x3dd713aafb46cb359c8711f4783836ba2e3e426c',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
  flipReserves: true,
};

const UNI_RAZE_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'RAZE' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'RAZE/WETH' as ContractType,
  stakingTokenAddress: '0x4fc47579ecf6aa76677ee142b6b75faf9eeafba8',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'RAZE',
  rewardTokenAddress: '0x5Eaa69B29f99C84Fe5dE8200340b4e9b4Ab38EaC',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xe2a80A76B084B51CFAe5B2C3e0FF5232e0408201',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  finished: true,
  flipReserves: true,
};

const UNI_BITE_ETH_CLOSED: StakingPool = {
  networkId: networkConstant.networkId.ethMainnet,
  token0Symbol: 'BITE' as ContractType,
  token1Symbol: 'WETH' as ContractType,
  stakingTokenSymbol: 'BITE/ETH' as ContractType,
  stakingTokenAddress: '0xa3053da613e5312c9e4b50edfb85f5a512c556d7',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'BITE',
  rewardTokenAddress: '0x4eED0fa8dE12D5a86517f214C2f11586Ba2ED88D',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xb12f0CbcC89457d44323139e6Bb0526Fd82f12F2',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
  finished: true,
};

export const CLOSED_POOLS_ETH = [
  L3P_POOL_CLOSED,
  BCUBE_POOL_CLOSED,
  BITE_POOL_CLOSED,
  SKYRIM_POOL_CLOSED,
  RFUEL_POOL_CLOSED_V2,
  RAZE_POOL_CLOSED,
  ROYA_POOL_CLOSED,
  FXF_POOL_CLOSED,
  OM_POOL_CLOSED,
  KYL_POOL_CLOSED,
  PKF_POOL_CLOSED,
  BCUBE_ETH_POOL_CLOSED,
  BONDLY_POOL_CLOSED,
  IMPACT_POOL_CLOSED,
  LABS_POOL_CLOSED,
  ZEN_UST_POOL_CLOSED,
  UNI_LABS_ETH_CLOSED,
  UNI_ROYA_ETH_CLOSED,
  UNI_LABS_CLOSED_TOLLBOOTH,
  OM_V1_ETH_CLOSED,
  BITE_ETH_LP_CLOSED,
  UNI_BBANK_ETH_CLOSED,
  UNI_BONDLY_ETH_CLOSED,
  UNI_BONDLY_USDT_CLOSED,
  UNI_RAZE_ETH_CLOSED,
  UNI_BITE_ETH_CLOSED,
];
