import BN from 'bignumber.js';
import {
  ALPHA,
  ATOM,
  ASVA,
  BAND,
  BCUBE,
  BITE,
  BNB,
  BNB_BBANK,
  BNB_CBD,
  BNB_FINE,
  BNB_MIST,
  BONDLY,
  BUSD,
  CIRUS,
  DAI,
  DPVN,
  EMONEY,
  ETH,
  FACTR,
  FXF,
  GAMER,
  KAVA,
  KYL,
  L3P,
  LABS,
  LABS_GROUP,
  LUNA,
  MATIC,
  MLT,
  OM,
  PKF,
  RAZE,
  RFUEL,
  ROSN,
  ROYA,
  SKYRIM,
  SPWN,
  STFI,
  UMAD,
  USDC,
  USDT,
  UST,
  WBNB,
  WBTC,
  WETH,
  ZENDAI,
  ZENETH,
  ZENUSDC,
  ZENUSDT,
  ZENUST,
  ZENWBTC,
  HELI,
  WMATIC,
} from '../constants/blockchain-constants';
import { INFO_MODAL } from '../constants/modal-constants';
import coingeckoService from '../services/coingecko.service';
import { authorization, logout } from './account-actions';
import { DARK_MODE, LIGHT_MODE, TOGGLE_MENU, UPDATE_CURRENCIES } from './action-types';
import * as modalActions from './modal-actions';

export const toggleMenu = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_MENU, payload: value });
};

export const darkMode = () => async (dispatch) => {
  window.localStorage.setItem('mantra-palette', 'dark');
  dispatch({ type: DARK_MODE });
};

export const lightMode = () => async (dispatch) => {
  window.localStorage.setItem('mantra-palette', 'light');
  dispatch({ type: LIGHT_MODE });
};

export const getCurrencies = () => async (dispatch, getStore) => {
  // const { data } = await apiService.price.getAllPrices();
  //
  // dispatch({
  //   type: UPDATE_CURRENCIES,
  //   payload: {
  //     USD: {
  //       [ETH]: data?.['ethereum']?.price || 0,
  //       [OM]: data?.['mantra-dao']?.price || 0,
  //       [RFUEL]: data?.['rio-defi']?.price || 0,
  //       [ROYA]: data?.['royale-finance']?.price || 0,
  //       [FXF]: data?.['finxflo']?.price || 0,
  //     },
  //   },
  // });
  const { lending } = getStore();

  const assetsCoingecoIdsMap = {
    [ETH]: 'ethereum',
    [BITE]: 'dragonbite',
    [BUSD]: 'binance-usd',
    [CIRUS]: 'cirus',
    [SKYRIM]: 'skyrim-finance',
    [ROYA]: 'royale',
    [OM]: 'mantra-dao',
    [FXF]: 'finxflo',
    [RFUEL]: 'rio-defi',
    [LABS]: 'labs-group',
    [LABS_GROUP]: 'labs-group',
    [L3P]: 'lepricon',
    [PKF]: 'polkafoundry',
    [KYL]: 'kylin-network',
    [BNB]: 'binancecoin',
    [BNB_FINE]: 'refinable',
    [RAZE]: 'raze-network',
    [ROSN]: 'roseon-finance',
    [BNB_MIST]: 'mist',
    [BNB_CBD]: 'greenheart-cbd',
    [BNB_BBANK]: 'blockbank',
    [BONDLY]: 'bondly',
    [WBNB]: 'wbnb',
    [WETH]: 'weth',
    [USDT]: 'tether',
    [USDC]: 'usd-coin',
    [BCUBE]: 'b-cube-ai',
    [ALPHA]: 'alpha-impact',
    [SPWN]: 'bitspawn',
    [MATIC]: 'matic-network',
    [MLT]: 'media-licensing-token',
    [DAI]: 'dai',
    [UST]: 'terrausd',
    [WBTC]: 'wrapped-bitcoin',
    [GAMER]: 'gamestation',
    [STFI]: 'startfi',
    [ATOM]: 'cosmos',
    [LUNA]: 'terra-luna',
    [BAND]: 'band-protocol',
    [KAVA]: 'kava',
    [EMONEY]: 'e-money',
    [DPVN]: 'sentinel',
    [FACTR]: 'defactor',
    [ASVA]: 'asva',
    [UMAD]: 'madworld',
    [HELI]: 'heliswap',
    [WMATIC]: 'polygon',
  };

  const coingeckoPrices = await coingeckoService.getPricesUSD(Object.values(assetsCoingecoIdsMap));
  const commonSupplyTokens = lending?.commonSupplyTokens;

  function getZenPrice(token, zenTokenSymbol) {
    const zenExchangeRate = commonSupplyTokens.find(
      (item) => item.zenTokenSymbol === zenTokenSymbol,
    )?.exchangeRate;

    const usdPrice = coingeckoPrices[assetsCoingecoIdsMap[token]]?.usd || 0;

    return new BN(usdPrice).times(zenExchangeRate);
  }

  dispatch({
    type: UPDATE_CURRENCIES,
    payload: {
      USD: {
        [ETH]: coingeckoPrices[assetsCoingecoIdsMap[ETH]]?.usd || 0,
        [OM]: coingeckoPrices[assetsCoingecoIdsMap[OM]]?.usd || 0,
        [RFUEL]: coingeckoPrices[assetsCoingecoIdsMap[RFUEL]]?.usd || 0,
        [ROYA]: coingeckoPrices[assetsCoingecoIdsMap[ROYA]]?.usd || 0,
        [FXF]: coingeckoPrices[assetsCoingecoIdsMap[FXF]]?.usd || 0,
        [L3P]: coingeckoPrices[assetsCoingecoIdsMap[L3P]]?.usd || 0,
        [LABS]: coingeckoPrices[assetsCoingecoIdsMap[LABS]]?.usd || 0,
        [LABS_GROUP]: coingeckoPrices[assetsCoingecoIdsMap[LABS_GROUP]]?.usd || 0,
        [PKF]: coingeckoPrices[assetsCoingecoIdsMap[PKF]]?.usd || 0,
        [KYL]: coingeckoPrices[assetsCoingecoIdsMap[KYL]]?.usd || 0,
        [BNB_FINE]: coingeckoPrices[assetsCoingecoIdsMap[BNB_FINE]]?.usd || 0,
        [BNB]: coingeckoPrices[assetsCoingecoIdsMap[BNB]]?.usd || 0,
        [RAZE]: coingeckoPrices[assetsCoingecoIdsMap[RAZE]]?.usd || 0,
        [BNB_MIST]: coingeckoPrices[assetsCoingecoIdsMap[BNB_MIST]]?.usd || 0,
        [BNB_CBD]: coingeckoPrices[assetsCoingecoIdsMap[BNB_CBD]]?.usd || 0,
        [BNB_BBANK]: coingeckoPrices[assetsCoingecoIdsMap[BNB_BBANK]]?.usd || 0,
        [BONDLY]: coingeckoPrices[assetsCoingecoIdsMap[BONDLY]]?.usd || 0,
        [WBNB]: coingeckoPrices[assetsCoingecoIdsMap[WBNB]]?.usd || 0,
        [WETH]: coingeckoPrices[assetsCoingecoIdsMap[WETH]]?.usd || 0,
        [USDT]: coingeckoPrices[assetsCoingecoIdsMap[USDT]]?.usd || 0,
        [USDC]: coingeckoPrices[assetsCoingecoIdsMap[USDC]]?.usd,
        [ROSN]: coingeckoPrices[assetsCoingecoIdsMap[ROSN]]?.usd || 0,
        [MLT]: coingeckoPrices[assetsCoingecoIdsMap[MLT]]?.usd || 0,
        [BITE]: coingeckoPrices[assetsCoingecoIdsMap[BITE]]?.usd || 0,
        [CIRUS]: coingeckoPrices[assetsCoingecoIdsMap[CIRUS]]?.usd || 0,
        [SKYRIM]: coingeckoPrices[assetsCoingecoIdsMap[SKYRIM]]?.usd || 0,
        [BCUBE]: coingeckoPrices[assetsCoingecoIdsMap[BCUBE]]?.usd || 0,
        [ALPHA]: coingeckoPrices[assetsCoingecoIdsMap[ALPHA]]?.usd || 0,
        [SPWN]: coingeckoPrices[assetsCoingecoIdsMap[SPWN]]?.usd || 0,
        [MATIC]: coingeckoPrices[assetsCoingecoIdsMap[MATIC]]?.usd || 0,
        [DAI]: coingeckoPrices[assetsCoingecoIdsMap[DAI]]?.usd || 0,
        [WBTC]: coingeckoPrices[assetsCoingecoIdsMap[WBTC]]?.usd || 0,
        [UST]: coingeckoPrices[assetsCoingecoIdsMap[UST]]?.usd || 0,
        [ZENDAI]: getZenPrice(DAI, 'zenDAI'),
        [ZENUSDT]: getZenPrice(USDT, 'zenUSDT'),
        [ZENUST]: getZenPrice(UST, 'zenUST'),
        [ZENUSDC]: getZenPrice(USDC, 'zenUSDC'),
        [ZENWBTC]: getZenPrice(WBTC, 'zenWBTC'),
        [ZENETH]: getZenPrice(ETH, 'zenETH'),
        [GAMER]: coingeckoPrices[assetsCoingecoIdsMap[GAMER]]?.usd || 0,
        [STFI]: coingeckoPrices[assetsCoingecoIdsMap[STFI]]?.usd || 0,
        [ATOM]: coingeckoPrices[assetsCoingecoIdsMap[ATOM]]?.usd || 0,
        [LUNA]: coingeckoPrices[assetsCoingecoIdsMap[LUNA]]?.usd || 0,
        [BAND]: coingeckoPrices[assetsCoingecoIdsMap[BAND]]?.usd || 0,
        [DPVN]: coingeckoPrices[assetsCoingecoIdsMap[DPVN]]?.usd || 0,
        [EMONEY]: coingeckoPrices[assetsCoingecoIdsMap[EMONEY]]?.usd || 0,
        [KAVA]: coingeckoPrices[assetsCoingecoIdsMap[KAVA]]?.usd || 0,
        [FACTR]: coingeckoPrices[assetsCoingecoIdsMap[FACTR]]?.usd || 0,
        [ASVA]: coingeckoPrices[assetsCoingecoIdsMap[ASVA]]?.usd || 0,
        [BUSD]: coingeckoPrices[assetsCoingecoIdsMap[BUSD]]?.usd || 0,
        [UMAD]: coingeckoPrices[assetsCoingecoIdsMap[UMAD]]?.usd || 0,
        [HELI]: coingeckoPrices[assetsCoingecoIdsMap[HELI]]?.usd || 0,
        [WMATIC]: coingeckoPrices[assetsCoingecoIdsMap[MATIC]]?.usd || 0,
      },
    },
  });
};

export const initApp = () => async (dispatch, getState) => {
  try {
    await Promise.all([
      new Promise(async (resolve) => {
        try {
          const typeAuthEth = localStorage.getItem('connected');
          console.log('typeAuthEth', typeAuthEth);
          await dispatch(authorization(typeAuthEth));
        } catch (e) {
          dispatch(logout());
          //window.location.reload();
        } finally {
          resolve();
        }
      }),
      (async () => {
        // To get the price for zen* stakings
        // if (networkId === networkConstant.networkId.ethMainnet) {
        //  await dispatch(getZenTokensAll());
        // }
        try {
          await dispatch(getCurrencies());
          setInterval(async () => {
            await dispatch(getCurrencies());
          }, 20 * 60 * 1000);
        } catch (error) {
          //console.log(error);
          //console.log('CoinGecko call is broken');
        }
      })(),
    ]);

    if (!getState().account.address) {
      //dispatch(modalActions.showModal(LOGIN_MODAL));
    }
  } catch (e) {
    const errors = [
      // eslint-disable-next-line max-len
      [
        'request failed or timed out',
        'The Ethereum infrastructure provider, Infura, is down, and there will be some connectivity issues until it is back up. Apologies for the inconvenience. Your funds are safe.',
      ],
    ];
    const error = errors.find((item) => e.message.indexOf(item[0]) >= 0);
    if (error) {
      dispatch(
        modalActions.showModal(INFO_MODAL, {
          title: 'Error',
          description: error[1],
          isClose: false,
        }),
      );
    }
    console.log(e);
    //throw e;
  }
};
