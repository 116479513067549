/* eslint-disable no-console */
import { EthereumProvider } from '@walletconnect/ethereum-provider';

import NetworkProviderService from '../network-provider-service';
import config from '../../../config/env';
import networkConstant from '../../../constants/network.constant';

class WalletConnectService extends NetworkProviderService {
  constructor() {
    super();
    this.initProvider();
  }

  async initProvider() {
    if (this.provider) return;
    const chains = [networkConstant.networkId.bscMainnet];
    //const chains = [networkConstant.networkId.bscTestnet];
    const projectId = 'b7e3e5aa5c1f3e0b0a3b8bf32d1290b0';
    const rpcMap = {
      [networkConstant.networkId.bscMainnet]: config.REACT_APP_BSC_RPC_URL,
      [networkConstant.networkId.bscTestnet]: config.REACT_APP_BSC_TEST_RPC_URL,
    };

    this.provider = await EthereumProvider.init({
      projectId,
      chains,
      rpcMap,
      showQrModal: true,
    });
  }

  async connect() {
    console.log('connect called');
    await this.initProvider();
  }

  get provider() {
    return this._provider;
  }

  set provider(instance) {
    this._provider = instance;
  }

  async enable() {
    return this.provider.enable();
  }

  async disconnect() {
    await this.initProvider();
    this.clearEvents();
    this.provider.disconnect();
    return true;
  }

  async initEvents() {
    this.provider.on('accountsChanged', this.eventAccountsChanged);
    this.provider.on('chainChanged', this.eventChainChanged);
    this.provider.on('disconnect', this.eventDisconnect);
  }

  eventAccountsChanged(value) {
    console.log('value', value);
    this.emit('accountsChanged', value);
  }

  eventChainChanged(value) {
    console.log('chain changed');
    this.emit('accountsChanged', value);
  }

  eventDisconnect() {
    this.emit('disconnect');
  }

  clearEvents() {
    super.clearEvents();
    if (this.provider.off) {
      this.provider.off('accountsChanged', this.eventAccountsChanged);
      this.provider.off('chainChanged', this.eventChainChanged);
      this.provider.off('disconnect', this.eventDisconnect);
    }
  }
}

export default WalletConnectService;
