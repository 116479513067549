import React from 'react';
import PropTypes from 'prop-types';
import styles from './footer.module.scss';
import LogoMilc from '../../assets/images/milc-logo.png';
import Inst from '../../assets/social/instagramFooter.svg';
import Email from '../../assets/social/email.svg';
import Medium from '../../assets/social/mediumFooter.svg';
import Twitter from '../../assets/social/twitterFooter.svg';
import Telegram from '../../assets/social/telegramFooter.svg';
import Discord from '../../assets/social/discord.svg';
import TikTok from '../../assets/social/tik-tok.svg';

const Footer = ({ colors, isDark, handleDarkLightChange }) => {
  const openNewTab = (link) => {
    window.open(link, '_blank');
  };

  return (
    <div className={styles.footerMain}>
      <div className={styles.footer}>
        <div className={styles.footerBox}>
          <div className={styles.footerItems}>
            <div className={styles.itemBox}>
              <img alt='Icon' src={LogoMilc} width={80}></img>
              <div className={styles.copyrightText}>© Copyright 2023 by Welt der Wender,</div>
              <div className={styles.copyrightText}>All Rights Reserved.</div>
            </div>
            <div className={styles.itemsBox}>
              <div className={styles.itemBox}>
                <div
                  onClick={() => openNewTab('mailto:info@weltderwunder.de')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img alt='Icon' src={Email} className={styles.socialIcons}></img>
                  <div className={styles.footerSocialText}>Email</div>
                </div>
                <div
                  className={styles.iconSocialText}
                  onClick={() => openNewTab('https://milc.medium.com')}
                >
                  {' '}
                  <img alt='Icon' src={Medium} className={styles.socialIconsMedium}></img>
                  <div className={styles.footerSocialText}>Medium</div>
                </div>
                <div
                  onClick={() => openNewTab('https://twitter.com/MILCplatform')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img
                    alt='Twitter'
                    src={Twitter}
                    width={27}
                    className={[styles.socialIcons, { marginLeft: 5, color: '#ffffff' }]}
                  ></img>
                  <div className={styles.footerSocialText}>Twitter</div>
                </div>
              </div>
              <div className={styles.itemBox}>
                <div
                  onClick={() => openNewTab('https://t.me/MILCplatform')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img alt='Icon' src={Telegram} className={styles.socialIcons}></img>
                  <div className={styles.footerSocialText}>Telegram</div>
                </div>
                <div
                  onClick={() => openNewTab('https://discord.com/invite/q5JhQYRbdF')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img alt='Icon' src={Discord} className={styles.socialIcons}></img>
                  <div className={styles.footerSocialText}>Discord</div>
                </div>
              </div>
              <div className={styles.itemBox}>
                <div
                  onClick={() => openNewTab('https://www.tiktok.com/@milcplatform')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img alt='Icon' src={TikTok} className={styles.socialIcons}></img>
                  <div className={styles.footerSocialText}>Tik Tok</div>
                </div>
                <div
                  onClick={() => openNewTab('https://www.instagram.com/milcmetaverse/')}
                  className={styles.iconSocialText}
                >
                  {' '}
                  <img alt='Icon' src={Inst} className={styles.socialIcons}></img>
                  <div className={styles.footerSocialText}>Instagram</div>
                </div>
              </div>
            </div>
            <div className={styles.itemBox}>
              <div
                onClick={() => openNewTab('https://metaverse.milc.global/')}
                className={styles.iconSocialText2}
              >
                {' '}
                <img alt='Icon' src={LogoMilc} width={50}></img>
                <div className={styles.footerSocialText2}>Metaverse</div>
              </div>
              <div
                onClick={() => openNewTab('https://services.milc.global/')}
                className={styles.iconSocialText2}
              >
                {' '}
                <img alt='Icon' src={LogoMilc} width={50}></img>
                <div className={styles.footerSocialText2}>Token Services</div>
              </div>
              <div
                onClick={() => openNewTab('https://community.milc.global/')}
                className={styles.iconSocialText2}
              >
                {' '}
                <img alt='Icon' src={LogoMilc} width={50}></img>
                <div className={styles.footerSocialText2}>MILC Community</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  colors: PropTypes.object.isRequired,
  isDark: PropTypes.bool.isRequired,
  handleDarkLightChange: PropTypes.func.isRequired,
};

Footer.defaultProps = {};

export default React.memo(Footer);
