/* eslint-disable no-console */
import NetworkProviderService from '../network-provider-service';
class TrustWalletService extends NetworkProviderService {
  constructor() {
    super();

    const ehtProvider = window.trustwallet;
    if (!ehtProvider) {
      const installPrompt = window.confirm(
        'Trust Wallet extension is not installed. Do you want to install it?',
      );
      if (installPrompt) window.open('https://trustwallet.com/browser-extension/');
    }

    if (ehtProvider.providers)
      this.provider = ehtProvider.providers.find((provider) => provider.isTrustWallet);
    else this.provider = ehtProvider;

    if (!this.provider) {
      throw new Error('Please set up TrustWallet properly');
    } else {
      //this.initEvents();
    }
  }

  async initProvider() {
    if (this.provider) return;
  }

  async connect() {
    console.log('connect called');
    await this.initProvider();
  }

  get provider() {
    return this._provider;
  }

  set provider(instance) {
    this._provider = instance;
  }

  async enable() {
    return this.provider.enable();
  }

  async disconnect() {
    await this.initProvider();
    this.clearEvents();
    this.provider.disconnect();
    return true;
  }

  initEvents() {
    this.provider.on('accountsChanged', this.eventAccountsChanged);
    this.provider.on('chainChanged', this.eventChainChanged);
    this.provider.on('disconnect', this.eventDisconnect);
  }

  eventAccountsChanged(value) {
    console.log('value', value);
    this.emit('accountsChanged', value);
  }

  eventChainChanged(value) {
    this.emit('accountsChanged', value);
  }

  eventDisconnect() {
    this.emit('disconnect');
  }

  clearEvents() {
    super.clearEvents();
    if (this.provider.off) {
      this.provider.off('accountsChanged', this.eventAccountsChanged);
      this.provider.off('chainChanged', this.eventChainChanged);
      this.provider.off('disconnect', this.eventChainChanged);
    }
  }
}

export default TrustWalletService;
