import BN from 'bignumber.js';
import mantraPoolContractService from '../services/contracts/mantra-pool-contract-service';
import mantraPoolContractServiceBsc from '../services/contracts/mantra-pool-contract-service-bsc';
import mantraPoolContractServicePolygon from '../services/contracts/mantra-pool-contract-service-polygon';
import {
  MANTRA_POOL_UPDATE_ROUND,
  MANTRA_POOL_SET_TOTAL_ENTRIES,
  MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE,
  MANTRA_POOL_UPDATE_HISTORY_OFFSET,
  MANTRA_POOL_UPDATE_HISTORY_DATA,
  MANTRA_POOL_CHANGE_HISTORY_LOADING,
  MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE,
  MANTRA_POOL_SET_WITHDRAWAL_PERIOD,
  MANTRA_POOL_SET_TOTAL_PARTICIPANTS,
} from './action-types';

import networkConstant from '../constants/network.constant';

import { cacheResultFunction } from '../helpers/function-helper';

export const getWinChance =
  (address, roundIndex, stackEntries = '0') =>
  async (dispatch, getState) => {
    const { currentContract } = dispatch(getCurrentAddressAndContract());

    let stakeBalance = (
      await currentContract
        .roundParticipant(roundIndex, address)
        .catch(() => ({ entriesCount: '0' }))
    ).entriesCount;

    if (new BN(stakeBalance).plus(stackEntries).toString(10) === '0') {
      return 0;
    }

    const round = await currentContract
      .round(roundIndex)
      .catch(() => ({ totalEntries: '0', participants: [] }));

    const { participants } = round;
    let { totalEntries } = round;
    let participantsCount = participants.length;
    totalEntries = new BN(totalEntries).plus(stackEntries).toString(10);

    if (stackEntries !== '0' && stakeBalance === '0') {
      participantsCount += 1;
    }

    stakeBalance = new BN(stakeBalance).plus(stackEntries).toString(10);

    if ([0, 1].includes(participantsCount)) {
      return participantsCount;
    }

    let result = 0;
    let losingChance = 1;
    let leftEntries = totalEntries;
    for (let i = 0; Math.min(participantsCount, 5) >= i; i += 1) {
      result += losingChance === 0 ? 0 : (losingChance * stakeBalance) / leftEntries;
      losingChance *= (leftEntries - stakeBalance) / leftEntries;
      leftEntries -= (totalEntries - stakeBalance) / (participantsCount - 1);
    }

    return result;
  };

/**
 * Get current round
 * And set round in cache
 * @type {function(...[*]=): *}
 */
export const getCurrentRound = (address, contract) => {
  return cacheResultFunction(`mantra-pool-round-${address}`, async () => {
    const cacheRound = localStorage.getItem(`mantra-pool-round-${address}`);
    if (cacheRound) {
      const { round, endsAt } = JSON.parse(cacheRound);
      if (endsAt && endsAt - Math.floor(new Date().getTime() / 1000) > 0) {
        return { round, endsAt };
      }
    }

    const roundsCount = +(await contract.roundsCount());

    let currentRound = 0;
    let currentEndsAt = null;

    const lastRound = await contract.round(roundsCount - 1);
    if (lastRound.endsAt - Math.floor(new Date().getTime() / 1000) < 0) {
      return { round: roundsCount, endsAt: currentEndsAt };
    }

    currentRound = roundsCount - 1;
    currentEndsAt = +lastRound.endsAt;
    for (let i = currentRound; i >= 0; i -= 1) {
      // eslint-disable-next-line no-await-in-loop
      const round = await contract.round(i);
      if (round.endsAt - Math.floor(new Date().getTime() / 1000) > 0) {
        currentRound = i;
        currentEndsAt = +round.endsAt;
      } else {
        return { round: currentRound, endsAt: currentEndsAt };
      }
    }

    return { round: currentRound, endsAt: currentEndsAt };
  });
};

export const changeCurrentPageHistory = (page) => (dispatch, getState) => {
  const { sizePerPage, totalDataSize } = getState().mantraPool.history;

  dispatch({ type: MANTRA_POOL_UPDATE_HISTORY_CURRENT_PAGE, payload: { currentPage: page } });
  dispatch({
    type: MANTRA_POOL_UPDATE_HISTORY_OFFSET,
    payload: { offset: totalDataSize - 1 - (page - 1) * sizePerPage },
  });
};

export const getDataHistory = () => async (dispatch, getState) => {
  try {
    const { currentContract } = dispatch(getCurrentAddressAndContract());

    const { sizePerPage, offset } = getState().mantraPool.history;

    dispatch({ type: MANTRA_POOL_CHANGE_HISTORY_LOADING, payload: { loading: true } });

    let startOffset = offset;

    const data = await Promise.all(
      Array(Math.min(sizePerPage, offset + 1))
        .fill(null)
        .map(() => {
          const promise = currentContract
            .round(startOffset)
            .then(({ endsAt, totalEntries, winners, index, closed, totalReward }) => ({
              isCreated: true,
              closed,
              endsAt,
              totalEntries,
              winners,
              totalReward,
              index,
            }))
            .catch(() => ({ isCreated: false }));
          startOffset -= 1;

          return promise;
        }),
    );

    dispatch({ type: MANTRA_POOL_UPDATE_HISTORY_DATA, payload: { data } });
  } finally {
    dispatch({ type: MANTRA_POOL_CHANGE_HISTORY_LOADING, payload: { loading: false } });
  }
};

export const initHistory = (currentContract) => async (dispatch, getState) => {
  const { currentRound } = getState().mantraPool;

  dispatch({
    type: MANTRA_POOL_UPDATE_HISTORY_TOTAL_DATA_SIZE,
    payload: { totalDataSize: currentRound + 1 },
  });
  dispatch({ type: MANTRA_POOL_UPDATE_HISTORY_OFFSET, payload: { offset: currentRound } });

  await dispatch(getDataHistory());
};

export const initData = () => async (dispatch) => {
  const { currentAddress, currentContract } = dispatch(getCurrentAddressAndContract());

  const currentRound = getCurrentRound(currentAddress, currentContract);
  const { round, endsAt } = await currentRound();

  dispatch({ type: MANTRA_POOL_UPDATE_ROUND, payload: { currentRound: round, endsAt } });

  const [roundInfo, withdrawalPeriod] = await Promise.all([
    currentContract.round(round).catch(() => null),
    currentContract.rewardWithdrawalPeriod(),
    dispatch(initHistory(currentContract)),
  ]);

  let totalEntries = '0';
  let participants = [];

  if (roundInfo) {
    totalEntries = roundInfo.totalEntries;
    participants = roundInfo.participants;
  }

  dispatch({ type: MANTRA_POOL_SET_TOTAL_PARTICIPANTS, payload: { participants } });
  dispatch({ type: MANTRA_POOL_SET_TOTAL_ENTRIES, payload: { totalEntries } });
  dispatch({ type: MANTRA_POOL_SET_WITHDRAWAL_PERIOD, payload: { withdrawalPeriod } });
};

export const getCurrentAddressAndContract = () => (dispatch, getState) => {
  const networkId = getState().account.networkId;
  let currentContract = null;
  let currentAddress = null;
  if (networkId === networkConstant.networkId.ethMainnet) {
    currentContract = mantraPoolContractService;
  } else if (networkId === networkConstant.networkId.bscMainnet) {
    currentContract = mantraPoolContractServiceBsc;
  } else if (networkId === networkConstant.networkId.polyMainnet) {
    currentContract = mantraPoolContractServicePolygon;
  } else {
    currentContract = mantraPoolContractServicePolygon;
  }
  currentAddress = currentContract._contract?._address;

  return { currentAddress, currentContract };
};
