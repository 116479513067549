import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import App from './app';
import Loader from './components/homeLoader/index';

import { INDEX_PATH, STAKING_PATH, CLAIMING_PATH } from './constants/router-constants';

const AsyncStaking = React.lazy(() => import('./screens/staking/Staking'));
const AsyncClaiming = React.lazy(() => import('./screens/claiming/Claiming'));
const AsyncPageNotFound = React.lazy(() => import('./screens/page-not-found/PageNotFound'));

const Routes = (props) => (
  <App>
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={INDEX_PATH} component={AsyncStaking} />
        <Route path={STAKING_PATH} component={AsyncStaking} />
        <Route path={CLAIMING_PATH} component={AsyncClaiming} />
        <Route render={() => <AsyncPageNotFound dark={props.dark} />} />
      </Switch>
    </Suspense>
  </App>
);

const mapStateToProps = (state) => ({
  dark: state.basic.dark,
  light: state.basic.light,
});

export default connect(mapStateToProps, {})(Routes);
