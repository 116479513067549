import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { BSC_CLOSED_POOLS } from './bscPoolsClosed';
import { StakingPool } from './staking.types';

const MLT_BNB_POOL: StakingPool = {
  networkId: networkConstant.networkId.bscTestnet,
  token0Symbol: 'MLT',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'MLT/BNB' as ContractType,
  stakingTokenAddress: '0x36883CB051e820e1d53eefd04baC7034dDdf5038',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'MLT',
  rewardTokenAddress: '0x94B5c07bBBF77F44Fceb8CdCF1d162b3af416342', // new token
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xA7A93374bf99a0F9A2802FDa1dEA97558ca60CfD',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
};

export const BSC_POOLS: StakingPool[] = [MLT_BNB_POOL, ...BSC_CLOSED_POOLS];
