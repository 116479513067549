import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
import account from './account-reducer';
import basic from './basic-reducer';
import history from './history-reducer';
import kusamaAccount from './kusama-account-reducer';
import lendingLiquidation from './lending-liquidation-reducer';
import lendingMarkets from './lending-markets-reducer';
import lendingPrices from './lending-prices-reducer';
import lending from './lending-reducer';
import mantraPool from './mantra-pool-reducer';
import merkleAirdrop from './matic-merkle-airdrop-account-reducer';
import stakingPool from './new-staking-reducer';
import RAZEETHUNIstake from './raze-eth-uni-stake-reducer';
import staking from './staking-reducer';
import StakingPools from './StakingPoolsReducer';
import token from './token-reducer';
import ZENDAIUNIstake from './zen-dai-uni-stake-reducer';
import ZENETHUNIstake from './zen-eth-uni-stake-reducer';
import ZENUSTUNIstake from './zen-ust-uni-stake-reducer';
import ZENWBTCUNIstake from './zen-wbtc-uni-stake-reducer';
import ZENUSDCUNIETHstake from './zenusdc-uni-eth-stake-reducer';
import ZENUSDTOMETHstake from './zenusdt-om-eth-stake-reducer';

export const Reducers = combineReducers({
  modal,
  basic,
  account,
  kusamaAccount,
  staking,
  RAZEETHUNIstake,
  token,
  history,
  merkleAirdrop,
  mantraPool,
  lending,
  lendingPrices,
  lendingMarkets,
  lendingLiquidation,
  ZENUSDTOMETHstake,
  ZENUSDCUNIETHstake,
  ZENDAIUNIstake,
  ZENUSTUNIstake,
  ZENWBTCUNIstake,
  ZENETHUNIstake,
  stakingPool,
  StakingPools,
});
export type State = ReturnType<typeof Reducers>;
