import Web3 from 'web3';

import networkConstant from '../constants/network.constant';

export default {
  getInstanceByNetworkId: function (networkId?: number) {
    switch (networkId) {
      case networkConstant.networkId.bscMainnet:
        return new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed1.defibit.io')); // for BSC main net
      case networkConstant.networkId.bscTestnet:
        return new Web3(
          new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'),
        );
      case networkConstant.networkId.ethSepolia:
        return new Web3(
          new Web3.providers.HttpProvider(
            'https://sepolia.infura.io/v3/262e4d37d2d5477386e1714b4ce256c1',
          ),
        );
      default:
        return new Web3();
    }
  },
};
