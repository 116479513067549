import networkConstant from '../../constants/network.constant';
import { ContractType } from '../../external/components/Icon/Icon';
import { BSC_CLOSED_POOLS } from './bscPoolsClosed';
import { StakingPool } from './staking.types';

const OM_NAKED_POOL: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'OM',
  stakingContractAddress: '0xEfc2d65302eb6345A7C0e212B791e0d45C2C3c91',
  stakingTokenAddress: '0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
};

const MLT_NAKED_POOL: StakingPool = {
  networkId: 56,
  stakingTokenSymbol: 'MLT',
  stakingContractAddress: '0xF0185520Cc773502f0f208433ca178f2f57157A9',
  stakingTokenAddress: '0x4518231a8fdf6ac553b9bbd51bbb86825b583263',
  stakingTokenDecimals: 18,
  poolType: 'SINGLE',
  tokenBalance: undefined,
  stakedBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  fee: 0,
  unstakeData: { unstakingDuration: 0, applicableAt: '0', usdAmount: '0', amount: 0 },
  strategyType: 'BLOCK',
};

const OM_BNB_POOL: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'BNB',
  token1Symbol: 'OM',
  stakingTokenSymbol: 'OM/BNB' as ContractType,
  stakingTokenAddress: '0x49837a48Abde7c525bdc86D9acBA39F739cBE22C',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'OM',
  rewardTokenAddress: '0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0xcbf42ace1dbd895ffdcabc1b841488542626014d',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
};

const MLT_BNB_POOL: StakingPool = {
  networkId: networkConstant.networkId.bscMainnet,
  token0Symbol: 'MLT',
  token1Symbol: 'WBNB',
  stakingTokenSymbol: 'MLT/BNB' as ContractType,
  stakingTokenAddress: '0x66Fe9c4e1260386483BF91Cb7106E75BA5D91A60',
  stakingTokenDecimals: 18,
  rewardTokenSymbol: 'MLT',
  rewardTokenAddress: '0xB72a20C7B8BD666f80AC053B0f4de20a787080F5',
  rewardTokenDecimals: 18,
  stakingContractAddress: '0x5831778dFF002c4808c62f541Fa8d61D0bBc3F18',
  poolType: 'LP',
  tokenBalance: undefined,
  stakedBalance: undefined,
  stakedBalanceUsd: '0',
  apr: undefined,
  unlockingTime: undefined,
  claimUnlockTime: undefined,
  unstakedBalance: undefined,
  lpTokenRate: 0,
  fee: 0,
  rewardBalance: undefined,
  estimatedDailyReward: undefined,
  rewardsEarned: undefined,
  strategyType: 'SECOND',
  flipReserves: true,
};

export const BSC_POOLS: StakingPool[] = [MLT_NAKED_POOL, MLT_BNB_POOL, ...BSC_CLOSED_POOLS];
