import EtherIcon from '../assets/images/tokens/token-type2/ether.svg';
import DaiIcon from '../assets/images/tokens/token-type2/dai.svg';
import TetherIcon from '../assets/images/tokens/token-type2/tether.svg';
import USDCIcon from '../assets/images/tokens/usdc.svg';
import WrappedBtcIcon from '../assets/images/tokens/token-type2/wrapped-btc.svg';
import LendingOmIcon from '../assets/images/tokens/token-type2/om.svg';
import CompoundIcon from '../assets/images/tokens/token-type2/compound.svg';
import LinkIcon from '../assets/images/tokens/token-type2/link.svg';
import CreamIcon from '../assets/images/tokens/token-type2/cream.svg';
import RFUELIcon from '../assets/images/tokens/rfuel.svg';
import AaveIcon from '../assets/images/tokens/aave.svg';
import UniIcon from '../assets/images/tokens/uni.svg';
import SushiIcon from '../assets/images/tokens/sushi.svg';
import SnxIcon from '../assets/images/tokens/snx.svg';
import YfiIcon from '../assets/images/tokens/yfi.svg';
import DsdIcon from '../assets/images/tokens/dsd.svg';
import BondlyIcon from '../assets/images/tokens/bondly.svg';
import PolsIcon from '../assets/images/tokens/pols.svg';
import Lending1inchIcon from '../assets/images/tokens/1inch.svg';
import RsrIcon from '../assets/images/tokens/rsr.svg';
import RoyaIcon from '../assets/images/tokens/roya.svg';
import FttIcon from '../assets/images/tokens/ftt.svg';
import SrmIcon from '../assets/images/tokens/srm.svg';
import BalIcon from '../assets/images/tokens/bal.svg';
import CrvIcon from '../assets/images/tokens/crv.svg';
import UmaIcon from '../assets/images/tokens/uma.svg';
import FraxIcon from '../assets/images/tokens/frax.svg';
import HegicIcon from '../assets/images/tokens/hegic.svg';
import RHegicIcon from '../assets/images/tokens/rhegic.svg';
import Zlotcon from '../assets/images/tokens/zlot.svg';
import ZhegicIcon from '../assets/images/tokens/zhegic.svg';
import WnxmIcon from '../assets/images/tokens/wnxm.svg';
import RenbtcIcon from '../assets/images/tokens/renbtc.svg';
import MkrIcon from '../assets/images/tokens/mkr.svg';
import BntIcon from '../assets/images/tokens/bnt.png';
import KncIcon from '../assets/images/tokens/knc.svg';
import CelIcon from '../assets/images/tokens/cel.svg';
import CornIcon from '../assets/images/tokens/corn.svg';
import Api3Icon from '../assets/images/tokens/api3.svg';
import MaticIcon from '../assets/images/tokens/matic.svg';
import BaoIcon from '../assets/images/tokens/bao.svg';
import UstIcon from '../assets/images/tokens/ust.png';
import DvgIcon from '../assets/images/tokens/dvg.svg';
import KylIcon from '../assets/images/KYL.svg';
import EnjIcon from '../assets/images/tokens/enj.png';
import LabsIcon from '../assets/images/labs-icon.svg';

import GrtIcon from '../assets/images/tokens/grt.svg';
import _0xIcon from '../assets/images/tokens/0x.svg';
import OmgIcon from '../assets/images/tokens/omg.svg';
import RookIcon from '../assets/images/tokens/rook.svg';
import UtkIcon from '../assets/images/tokens/utk.svg';
import PaidIcon from '../assets/images/tokens/paid.svg';
import AlphaIcon from '../assets/images/tokens/alpha.svg';
import RgtIcon from '../assets/images/tokens/rgt.svg';

import config from '../config/env';
import BN from 'bignumber.js';

export const LENDING_TOKENS_CONSTANTS = {
  // zenTokens
  'zenETH': 'zenETH',
  'zenUSDT': 'zenUSDT',
  'zenUSDC': 'zenUSDC',
  'zenWBTC': 'zenWBTC',
  'zenCOMP': 'zenCOMP',
  'zenDAI': 'zenDAI',
  'zenCREAM': 'zenCREAM',
  'zenRFUEL': 'zenRFUEL',
  'zenOM': 'zenOM',
  'zenLINK': 'zenLINK',
  'zenAAVE': 'zenAAVE',
  'zenUNI': 'zenUNI',
  'zenSUSHI': 'zenSUSHI',
  'zenSNX': 'zenSNX',
  'zenYFI': 'zenYFI',
  'zenDSD': 'zenDSD',
  'zenBONDLY': 'zenBONDLY',
  'zenPOLS': 'zenPOLS',
  'zen1INCH': 'zen1INCH',
  'zenRSR': 'zenRSR',
  'zenROYA': 'zenROYA',
  'zenFTT': 'zenFTT',
  'zenSRM': 'zenSRM',
  'zenBAL': 'zenBAL',
  'zenCRV': 'zenCRV',
  'zenUMA': 'zenUMA',
  'zenRUNE': 'zenRUNE',
  'zenFRAX': 'zenFRAX',
  'zenHEGIC': 'zenHEGIC',
  'zenrHEGIC': 'zenrHEGIC',
  'zenMPH': 'zenMPH',
  'zenzLOT': 'zenzLOT',
  'zenzHEGIC': 'zHEGIC',
  'zenWHITE': 'zenWHITE',
  'zenWNXM': 'zenWNXM',
  'zenRENBTC': 'zenRENBTC',
  'zenMKR': 'zenMKR',
  'zenBNT': 'zenBNT',
  'zenKNC': 'zenKNC',
  'zenCEL': 'zenCEL',
  'zenCORN': 'zenCORN',
  'zenAPI3': 'zenAPI3',
  'zenMATIC': 'zenMATIC',
  'zenBAO': 'zenBAO',
  'zenUST': 'zenUST',
  'zenDVG': 'zenDVG',
  'zenGRT': 'zenGRT',
  'zen0x': 'zen0x',
  'zenOMG': 'zenOMG',
  'zenINJ': 'zenINJ',
  'zenBADGER': 'zenBADGER',
  'zenROOK': 'zenROOK',
  'zenUTK': 'zenUTK',
  'zenPAID': 'zenPAID',
  'zenALPHA': 'zenALPHA',
  'zenRGT': 'zenRGT',
  'zenFXF': 'zenFXF',
  'zenENJ': 'zenENJ',
  'zenLABS': 'zenLABS',
  'zenKYL': 'zenKYL',
  // tokens
  'ETH': 'ETH',
  'USDT': 'USDT',
  'USDC': 'USDC',
  'WBTC': 'WBTC',
  'COMP': 'COMP',
  'DAI': 'DAI',
  'CREAM': 'CREAM',
  'RFUEL': 'RFUEL',
  'OM': 'OM',
  'LINK': 'LINK',
  'AAVE': 'AAVE',
  'UNI': 'UNI',
  'SUSHI': 'SUSHI',
  'SNX': 'SNX',
  'YFI': 'YFI',
  'DSD': 'DSD',
  'BONDLY': 'BONDLY',
  'POLS': 'POLS',
  '1INCH': '1INCH',
  'RSR': 'RSR',
  'ROYA': 'ROYA',
  'FTT': 'FTT',
  'SRM': 'SRM',
  'BAL': 'BAL',
  'CRV': 'CRV',
  'UMA': 'UMA',
  'RUNE': 'RUNE',
  'FRAX': 'FRAX',
  'HEGIC': 'HEGIC',
  'rHEGIC': 'rHEGIC',
  'MPH': 'MPH',
  'zLOT': 'zLOT',
  'zHEGIC': 'zHEGIC',
  'WHITE': 'WHITE',
  'WNXM': 'WNXM',
  'RENBTC': 'RENBTC',
  'MKR': 'MKR',
  'BNT': 'BNT',
  'KNC': 'KNC',
  'CEL': 'CEL',
  'CORN': 'CORN',
  'API3': 'API3',
  'MATIC': 'MATIC',
  'BAO': 'BAO',
  'UST': 'UST',
  'DVG': 'DVG',
  'GRT': 'GRT',
  '0x': '0x',
  'OMG': 'OMG',
  'INJ': 'INJ',
  'BADGER': 'BADGER',
  'ROOK': 'ROOK',
  'UTK': 'UTK',
  'PAID': 'PAID',
  'ALPHA': 'ALPHA',
  'RGT': 'RGT',
  'FXF': 'FXF',
  'KYL': 'KYL',
  'ENJ': 'ENJ',
  'LABS': 'LABS',
  'GAMER': 'GAMER',
  'STFI': 'STFI',
  'BNB': 'BNB',
  'FACTR': 'FACTR',
};

export const LENDING_ZEN_TOKENS = {
  [config.REACT_APP_LENDING_ZEN_OM_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenOM,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.OM,
    underlyingIcon: LendingOmIcon,
    underlyingName: 'mantra-dao',
    isFixedInList: true,
    isNew: false,
    isPaused: false,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_ETH_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenETH,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.ETH,
    underlyingIcon: EtherIcon,
    underlyingName: 'ethereum',
    isFixedInList: true,
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_USDC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUSDC,
    underlyingDecimals: 6,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.USDC,
    underlyingIcon: USDCIcon,
    underlyingName: 'usd-coin',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_USDT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUSDT,
    underlyingDecimals: 6,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.USDT,
    underlyingIcon: TetherIcon,
    underlyingName: 'tether',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_DAI_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenDAI,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.DAI,
    underlyingIcon: DaiIcon,
    underlyingName: 'multi-collateral-dai',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_WBTC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenWBTC,
    underlyingDecimals: 8,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.WBTC,
    underlyingIcon: WrappedBtcIcon,
    underlyingName: 'wrapped-bitcoin',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_COMP_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenCOMP,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.COMP,
    underlyingIcon: CompoundIcon,
    underlyingName: 'compound',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_CREAM_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenCREAM,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.CREAM,
    underlyingIcon: CreamIcon,
    underlyingName: 'cream-finance',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_RFUEL_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenRFUEL,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.RFUEL,
    underlyingIcon: RFUELIcon,
    underlyingName: 'rio-defi',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_LINK_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenLINK,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.LINK,
    underlyingIcon: LinkIcon,
    underlyingName: 'chainlink',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_AAVE_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenAAVE,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.AAVE,
    underlyingIcon: AaveIcon,
    underlyingName: 'aave',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_UNI_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUNI,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.UNI,
    underlyingIcon: UniIcon,
    underlyingName: 'uniswap',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_SUSHI_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenSUSHI,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.SUSHI,
    underlyingIcon: SushiIcon,
    underlyingName: 'sushiswap',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_SNX_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenSNX,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.SNX,
    underlyingIcon: SnxIcon,
    underlyingName: 'synthetix-network-token',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_YFI_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenYFI,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.YFI,
    underlyingIcon: YfiIcon,
    underlyingName: 'yearn-finance',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_DSD_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenDSD,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.DSD,
    underlyingIcon: DsdIcon,
    underlyingName: 'dynamic-set-dollar',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_BONDLY_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenBONDLY,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.BONDLY,
    underlyingIcon: BondlyIcon,
    underlyingName: 'bondly',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_POLS_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenPOLS,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.POLS,
    underlyingIcon: PolsIcon,
    underlyingName: 'polkastarter',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_1INCH_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zen1INCH,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS['1INCH'],
    underlyingIcon: Lending1inchIcon,
    underlyingName: '1inch',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_RSR_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenRSR,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.RSR,
    underlyingIcon: RsrIcon,
    underlyingName: 'reserve-rights',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_ROYA_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenROYA,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.ROYA,
    underlyingIcon: RoyaIcon,
    underlyingName: 'royale-finance',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_FTT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenFTT,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.FTT,
    underlyingIcon: FttIcon,
    underlyingName: 'ftx-token',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_SRM_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenSRM,
    underlyingDecimals: 6,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.SRM,
    underlyingIcon: SrmIcon,
    underlyingName: 'serum',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_BAL_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenBAL,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.BAL,
    underlyingIcon: BalIcon,
    underlyingName: 'balancer',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_CRV_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenCRV,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.CRV,
    underlyingIcon: CrvIcon,
    underlyingName: 'curve-dao-token',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_UMA_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUMA,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.UMA,
    underlyingIcon: UmaIcon,
    underlyingName: 'uma',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  // [config.REACT_APP_LENDING_ZEN_RUNE_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenRUNE,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.RUNE,
  //   underlyingIcon: RuneIcon,
  //   underlyingName: 'thorchain',
  //   isNew: false,
  //   isPaused: false,
  //   isAvailableForBorrow: true,
  // },
  [config.REACT_APP_LENDING_ZEN_FRAX_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenFRAX,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.FRAX,
    underlyingIcon: FraxIcon,
    underlyingName: 'frax',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_HEGIC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenHEGIC,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.HEGIC,
    underlyingIcon: HegicIcon,
    underlyingName: 'hegic',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_RHEGIC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenrHEGIC,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.rHEGIC,
    underlyingIcon: RHegicIcon,
    underlyingName: 'rhegic',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  // [config.REACT_APP_LENDING_ZEN_MPH_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenMPH,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.MPH,
  //   underlyingIcon: MphIcon,
  //   underlyingName: '88mph',
  //   isNew: false,
  //   isPaused: false,
  //   isAvailableForBorrow: true,
  // },
  [config.REACT_APP_LENDING_ZEN_ZLOT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenzLOT,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.zLOT,
    underlyingIcon: Zlotcon,
    underlyingName: 'zlot-finance',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_ZHEGIC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenzHEGIC,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.zHEGIC,
    underlyingIcon: ZhegicIcon,
    underlyingName: 'zhegic',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  // [config.REACT_APP_LENDING_ZEN_WHITE_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenWHITE,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.WHITE,
  //   underlyingIcon: WhiteIcon,
  //   underlyingName: 'whiteheart',
  //   isNew: false,
  //   isPaused: false,
  //   isAvailableForBorrow: true,
  // },
  [config.REACT_APP_LENDING_ZEN_WNXM_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenWNXM,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.WNXM,
    underlyingIcon: WnxmIcon,
    underlyingName: 'wrapped-nxm',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_RENBTC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenRENBTC,
    underlyingDecimals: 8,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.RENBTC,
    underlyingIcon: RenbtcIcon,
    underlyingName: 'renbtc',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_MKR_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenMKR,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.MKR,
    underlyingIcon: MkrIcon,
    underlyingName: 'maker',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_BNT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenBNT,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.BNT,
    underlyingIcon: BntIcon,
    underlyingName: 'bancor',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_KNC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenKNC,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.KNC,
    underlyingIcon: KncIcon,
    underlyingName: 'kyber-network',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_CEL_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenCEL,
    underlyingDecimals: 4,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.CEL,
    underlyingIcon: CelIcon,
    underlyingName: 'celsius',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_CORN_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenCORN,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.CORN,
    underlyingIcon: CornIcon,
    underlyingName: 'cornichon',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_API3_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenAPI3,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.API3,
    underlyingIcon: Api3Icon,
    underlyingName: 'api3',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_MATIC_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenMATIC,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.MATIC,
    underlyingIcon: MaticIcon,
    underlyingName: 'matic-network',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_BAO_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenBAO,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.BAO,
    underlyingIcon: BaoIcon,
    underlyingName: 'bao-finance',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_UST_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUST,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.UST,
    underlyingIcon: UstIcon,
    underlyingName: 'terrausd',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_DVG_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenDVG,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.DVG,
    underlyingIcon: DvgIcon,
    underlyingName: 'daoventures',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_GRT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenGRT,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.GRT,
    underlyingIcon: GrtIcon,
    underlyingName: 'the-graph',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_0X_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zen0x,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS['0x'],
    underlyingIcon: _0xIcon,
    underlyingName: '0x',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_OMG_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenOMG,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.OMG,
    underlyingIcon: OmgIcon,
    underlyingName: 'omg',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  // [config.REACT_APP_LENDING_ZEN_INJ_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenINJ,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.INJ,
  //   underlyingIcon: InjIcon,
  //   underlyingName: 'injective-protocol',
  //   isNew: false,
  //   isPaused: false,
  //   isAvailableForBorrow: true,
  // },
  // [config.REACT_APP_LENDING_ZEN_BADGER_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenBADGER,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.BADGER,
  //   underlyingIcon: BadgerIcon,
  //   underlyingName: 'badger-dao',
  //   isNew: false,
  //   isPaused: true,
  //   isAvailableForBorrow: false,
  // },
  [config.REACT_APP_LENDING_ZEN_ROOK_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenROOK,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.ROOK,
    underlyingIcon: RookIcon,
    underlyingName: 'keeperdao',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_UTK_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenUTK,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.UTK,
    underlyingIcon: UtkIcon,
    underlyingName: 'utrust',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  // [config.REACT_APP_LENDING_ZEN_PAID_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenPAID,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.PAID,
  //   underlyingIcon: PaidIcon,
  //   underlyingName: 'paid-network',
  //   isNew: true,
  //   isAvailableForBorrow: true,
  // },
  [config.REACT_APP_LENDING_ZEN_ALPHA_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenALPHA,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.ALPHA,
    underlyingIcon: AlphaIcon,
    underlyingName: 'alpha-finance-lab',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_RGT_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenRGT,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.RGT,
    underlyingIcon: RgtIcon,
    underlyingName: 'rari-governance-token',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  // [config.REACT_APP_LENDING_ZEN_FXF_TOKEN_ADDRESS]: {
  //   zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenFXF,
  //   underlyingDecimals: 18,
  //   underlyingSymbol: LENDING_TOKENS_CONSTANTS.FXF,
  //   underlyingIcon: FxfIcon,
  //   underlyingName: 'finxflo',
  //   isNew: false,
  //   isPaused: true,
  //   isAvailableForBorrow: true,
  // },
  [config.REACT_APP_LENDING_ZEN_KYL_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenKYL,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.KYL,
    underlyingIcon: KylIcon,
    underlyingName: 'kylin',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_PAID_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenPAID,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.PAID,
    underlyingIcon: PaidIcon,
    underlyingName: 'paid-network',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: false,
  },
  [config.REACT_APP_LENDING_ZEN_ENJ_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenENJ,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.ENJ,
    underlyingIcon: EnjIcon,
    underlyingName: 'enjin-coin',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
  [config.REACT_APP_LENDING_ZEN_LABS_TOKEN_ADDRESS]: {
    zenTokenSymbol: LENDING_TOKENS_CONSTANTS.zenLABS,
    underlyingDecimals: 18,
    underlyingSymbol: LENDING_TOKENS_CONSTANTS.LABS,
    underlyingIcon: LabsIcon,
    underlyingName: 'labs-group',
    isNew: false,
    isPaused: true,
    isAvailableForBorrow: true,
  },
};

export const OPERATION_DECIMAL_PLACES = 18;
export const DAYS_PER_YEAR = 365;
export const LENDING_UPDATE_DATA_INTERVAL = 10000;
export const TOKEN_ALLOWANCE_MIN_AMOUNT = new BN(0); // NOTE adjust it if needed
export const TOKEN_ALLOWANCE_MIN_AMOUNT_UINT96 = '0xfffffff';
export const PERCENT_DECIMALS = 15;

export const MAX_WITHDRAWAL_PERCENT = 0.999;
export const MAX_BORROW_PERCENT = 0.999;
export const COLLATERAL_ABOUT_LINK =
  'https://medium.com/mantra-dao/zenterest-launches-bringing-lending-borrowing-to-the-sherpas-c83136fa5e8c';
export const ZEN_TOKEN_DECIMALS = 8;

// lending prices
export const LENDING_PRICES_UPDATE_DATA_INTERVAL = 10000;
export const LENDING_PRICES_MARKETS_DATA_INTERVAL = 10000;

export const ZEN_TOKEN_ADRESSES = Object.keys(LENDING_ZEN_TOKENS)
  .filter((address) => address && address !== 'undefined')
  .map((address) => address.toLowerCase());

export const LENDING_SORT_COLUMNS = {
  SUPPLY_APY: 'SUPPLY_APY',
  BORROW_APY: 'BORROW_APY',
  ASSET: 'ASSET',
};

export const SORT_BY = {
  ASSET: 'ASSET',
  APY: 'APY',
  WALLET: 'WALLET',
};
