import networkConstants from '../constants/network.constant';
import { NetworkId } from '../types/network.types';

export default {
  findNetworkType(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return networkConstants.networkType.binance;
      default:
        return networkConstants.networkType.ethereum;
    }
  },

  findNetworkName(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return 'Binance Smart Chain';
      default:
        return 'Ethereum';
    }
  },

  findTokenType(chainId: NetworkId) {
    switch (chainId) {
      case networkConstants.networkId.bscMainnet:
      case networkConstants.networkId.bscTestnet:
        return 'BEP-20';
      default:
        return 'ERC-20';
    }
  },

  findBlockExplorerUrl(networkId?: NetworkId) {
    switch (networkId) {
      case networkConstants.networkId.bscMainnet:
        return 'https://bscscan.com';
      case networkConstants.networkId.bscTestnet:
        return 'https://testnet.bscscan.com';
      default:
        return 'https://etherscan.io';
    }
  },
};
