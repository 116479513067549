/**
 * @TODO Resolve using `a` elements without a link
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connectModal } from 'redux-modal';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch } from 'react-redux';
import { LOGIN_MODAL } from '../../../constants/modal-constants';
import EthWallets from './eth-wallets';
import s from './login-modal.module.scss';
import * as modalActions from '../../../actions/modal-actions';
import closeIcon from '../../../assets/images/closeLogin.svg';

const renderStep = (step, setActiveStep, global) => {
  return <EthWallets global={global} />;
};

const LoginModal = ({ show, global }) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(2);

  return (
    <Dialog open={show} className={s.modal}>
      <div className={s.container} style={{ backgroundColor: global.dark ? '#282626' : '#F1F3F9' }}>
        <button className={s.close} onClick={() => dispatch(modalActions.hideModal(LOGIN_MODAL))}>
          <img src={closeIcon} alt='close' />
        </button>
        {renderStep(activeStep, setActiveStep, global)}
      </div>
    </Dialog>
  );
};

LoginModal.propTypes = {
  show: PropTypes.bool,
  global: PropTypes.object.isRequired,
};

LoginModal.defaultProps = {
  show: true,
};

export default connectModal({ name: LOGIN_MODAL })(LoginModal);
