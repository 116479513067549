/* eslint-disable no-console */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_AUTH } from '../../../constants/account-contants';
import * as accountActions from '../../../actions/account-actions';
import styles from './login-modal.module.scss';
import { LOGIN_MODAL } from '../../../constants/modal-constants';
import Item from './item';
import * as modalActions from '../../../actions/modal-actions';
import loadingIcon from '../../../assets/images/loading.png';
import metamaskIcon from '../../../assets/images/wallets/metaMask-new.svg';
import walletConnectIcon from '../../../assets/images/wallets/walletConnect-new.svg';
import walletLinkIcon from '../../../assets/images/wallets/coinbase-new.svg';
import trustWallet from '../../../assets/images/wallets/trustWallet-new.svg';
import okkWallet from '../../../assets/images/wallets/okkWallet-new.svg';

const EthWallets = ({ global }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const typeAuth = useSelector((state) => state.account.auth);

  const connect = async (type) => {
    try {
      setLoading(true);
      if (type === TYPE_AUTH.WALLET_CONNECT) {
        setTimeout(() => {
          dispatch(modalActions.hideModal(LOGIN_MODAL));
        }, 1000);
      }
      await dispatch(accountActions.connect(type));
    } catch (e) {
    } finally {
      setLoading(false);
      //window.location.reload();
    }
  };

  return (
    <>
      <h3 className={styles.title} id='alert-dialog-title'>
        Connect wallet
      </h3>
      <p className={styles.description}>
        Choose your wallet provider to access more functionality.
      </p>
      {loading ? (
        <div className={styles.loading}>
          <img src={loadingIcon} className='rotate' alt='' />
        </div>
      ) : (
        <ul className={styles.list}>
          <Item
            title='MetaMask'
            icon={metamaskIcon}
            onClick={() => connect(TYPE_AUTH.METAMASK)}
            active={typeAuth === TYPE_AUTH.METAMASK}
          />

          <Item
            title='WalletConnect'
            icon={walletConnectIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_CONNECT)}
            active={typeAuth === TYPE_AUTH.WALLET_CONNECT}
          />

          <Item
            title='Coinbase'
            icon={walletLinkIcon}
            onClick={() => connect(TYPE_AUTH.WALLET_LINK)}
            active={typeAuth === TYPE_AUTH.WALLET_LINK}
          />
          <Item
            title='Trust Wallet'
            icon={trustWallet}
            onClick={() => connect(TYPE_AUTH.TRUST_WALLET)}
            active={typeAuth === TYPE_AUTH.TRUST_WALLET}
          />
          <Item
            title='OKX Wallet'
            icon={okkWallet}
            onClick={() => connect(TYPE_AUTH.OKX_WALLET)}
            active={typeAuth === TYPE_AUTH.OKX_WALLET}
          />
        </ul>
      )}
    </>
  );
};

EthWallets.propTypes = {
  global: PropTypes.object.isRequired,
};

export default EthWallets;
