export const TYPE_AUTH = {
  METAMASK: 'metamask',
  WALLET_CONNECT: 'wallet_connect',
  WALLET_CONNECT_BSC: 'wallet_connect_bsc',
  WALLET_CONNECT_POLYGON: 'wallet_connect_polygon',
  WALLET_LINK: 'wallet_link', //coinbase
  TRUST_WALLET: 'trust_wallet',
  OKX_WALLET: 'okx_wallet',
};

export const ETH_TYPE_AUTH = [
  TYPE_AUTH.METAMASK,
  TYPE_AUTH.WALLET_CONNECT,
  TYPE_AUTH.WALLET_CONNECT_BSC,
  TYPE_AUTH.WALLET_CONNECT_POLYGON,
  TYPE_AUTH.TRUST_WALLET,
  TYPE_AUTH.WALLET_LINK,
];

export const TYPE_AUTH_TITLE = {
  [TYPE_AUTH.METAMASK]: 'MetaMask',
  [TYPE_AUTH.WALLET_CONNECT]: 'Wallet Connect',
  [TYPE_AUTH.WALLET_CONNECT]: 'Wallet Connect BSC',
  [TYPE_AUTH.WALLET_LINK]: 'Wallet Link',
  [TYPE_AUTH.TRUST_WALLET]: 'Trust Wallet',
  [TYPE_AUTH.OKX_WALLET]: 'OKX Wallet',
};
