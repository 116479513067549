/* eslint-disable no-console */
import Web3 from 'web3';
import web3Service from '../services/web3-service';
import WalletConnectService from '../services/network-providers/wallet-connect-service';
import TrustWalletService from '../services/network-providers/trust-wallet-service';
import OkxWalletService from '../services/network-providers/okx-wallet-service';
import WalletLinkService from '../services/network-providers/wallet-link-service';
import { TYPE_AUTH } from '../constants/account-contants';
import * as tokenActions from './token-actions';
import { SET_AUTH, SET_NETWORK_TYPE, SET_NETWORK_ID, LOGOUT } from './action-types';
import * as modalActions from './modal-actions';

import { toastError } from './toast-actions';
import { ACCOUNT_MODAL } from '../constants/modal-constants';
import networkConstants from '../constants/network.constant';
import networkHelpers from '../helpers/network.helpers';
import networkConstant from '../constants/network.constant';
import MetaMaskService from '../services/network-providers/metamask-service';
import { setActiveAsset } from './staking-actions';
import { BNB_FINE, OM_POLYGON } from '../constants/blockchain-constants';
import config from '../config/env';

export const logout = () => (dispatch, getState) => {
  try {
    const { address } = getState().account;
    localStorage.removeItem('connected');
    localStorage.removeItem(`${address}_notShowOmStakingItem`);

    web3Service.disconnect();
    dispatch({ type: LOGOUT });
    dispatch(modalActions.hideModal(ACCOUNT_MODAL));

    /*
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    */
  } catch (error) {
    console.error('Error disconnecting:', error);
    toastError('Failed to disconnect from MetaMask. Please try again.');
  }
};

export const logoutOld = () => (dispatch, getState) => {
  const { address } = getState().account;
  localStorage.removeItem('connected');
  localStorage.removeItem(`${address}_notShowOmStakingItem`);
  web3Service.disconnect();

  dispatch({ type: LOGOUT });
  dispatch(modalActions.hideModal(ACCOUNT_MODAL));
};

async function createWalletConnectInstance() {
  console.log('createWalletConnectInstance');
  const instance = new WalletConnectService();
  await instance.initProvider(); // Wait for initialization to complete
  return instance;
}

export const authorization = (typeAuth) => async (dispatch, getState) => {
  console.log('authorization', typeAuth);
  //let networkId = await web3Service.eth.net.getId();
  let networkId = await web3Service.eth.net.getId();
  console.log('networkId authorization', networkId);
  const networkType = networkHelpers.findNetworkType(networkId);
  const { dark } = getState().basic;

  if (!typeAuth) {
    console.log('error', typeAuth);
    throw Error('Type auth is not correct');
  }

  if (!Object.values(networkConstants.networkId).includes(networkId)) {
    console.log('Unexpected chain ID');
    //throw Error('Unexpected chain ID');
  }

  console.log('dalje');
  let instance;

  const typeAuthStorage = localStorage.getItem('connected');
  if (typeAuth !== typeAuthStorage) localStorage.clear();
  switch (typeAuth) {
    case TYPE_AUTH.METAMASK:
      instance = new MetaMaskService();
      //networkId = instance._provider.chainId;
      break;
    case TYPE_AUTH.WALLET_CONNECT:
      console.log('clear storage');
      instance = await createWalletConnectInstance();
      networkId = instance._provider.chainId;
      break;
    case TYPE_AUTH.WALLET_LINK:
      instance = new WalletLinkService(networkId, dark);
      break;
    case TYPE_AUTH.TRUST_WALLET:
      instance = new TrustWalletService();
      break;
    case TYPE_AUTH.OKX_WALLET:
      instance = new OkxWalletService();
      break;
    default:
      instance = null;
      break;
  }

  console.log('instance', instance);
  if (instance) {
    web3Service.changeProvider(instance);

    console.log('typeAuth', typeAuth);

    /** When using MetaMask, we switch the Web3 provider to a respective RPC endpoint. */
    if (typeAuth === TYPE_AUTH.METAMASK) {
      console.log('networktype', networkType);
      switch (networkType) {
        case networkConstants.networkType.ethereum:
          // hotfix
          // see at https://mantra-dao.slack.com/archives/C01F0RUA3UN/p1617720837033300?thread_ts=1617716731.030600&cid=C01F0RUA3UN
          web3Service._web3 = new Web3(instance.provider);
          break;
        case networkConstants.networkType.binance:
          console.log('binance', instance);
          web3Service._web3 = new Web3(
            new Web3.providers.HttpProvider(config.REACT_APP_BSC_RPC_URL),
          );
          break;
        default:
          web3Service._web3 = new Web3(instance.provider);
          break;
      }
    }

    const [address] = await web3Service.providerInstance.enable();

    web3Service.providerInstance.on('accountsChanged', (value) => {
      console.log(value, address, 'provider instance check change');
      if (value[0] !== address) {
        window.location.reload();
      }
    });

    web3Service.providerInstance.on('chainChanged', () => {
      console.log('ulaz 2');
      //window.location.reload();
    });

    web3Service.providerInstance.on('disconnect', () => {
      console.log('ulaz 3');

      dispatch(logout());
      // window.location.reload();
    });

    dispatch({ type: SET_AUTH, payload: { type: typeAuth, address } });

    if (
      (networkType === networkConstant.networkType.binance && typeAuth === TYPE_AUTH.METAMASK) ||
      typeAuth === TYPE_AUTH.WALLET_CONNECT_BSC
    ) {
      console.log('ulaz 5');

      dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
      dispatch({ type: SET_NETWORK_ID, payload: networkId });

      await Promise.all([
        dispatch(tokenActions.getOmBcsBalance()),
        dispatch(tokenActions.getBnbFineBalance()),
        dispatch(tokenActions.getBnbOmBalance()),
        dispatch(tokenActions.getBnbMistBalance()),
        dispatch(tokenActions.getBnbCbdBalance()),
        dispatch(tokenActions.getBnbBbankBalance()),
        dispatch(tokenActions.getBnbRosnBalance()),
        dispatch(tokenActions.getRosnBalance()),
        dispatch(tokenActions.getMltBalance()),
        dispatch(tokenActions.getBondlyWbnbBalance()),
        dispatch(tokenActions.getBondlyBscBalance()),
        dispatch(tokenActions.getBnbMltBalance()),
        // TODO: Uncomment this
        // dispatch(tokenActions.getStfiWbnbUniBalance()),
        // dispatch(tokenActions.getStfiBalance()),
        dispatch(tokenActions.getL3pWbnbUniBalance()),
        dispatch(tokenActions.getFactrBalance()),
        dispatch(tokenActions.getFactrWbnbUniBalance()),
        // TODO: Uncomment GAMER_BNB
        // dispatch(tokenActions.getGamerWbnbUniBalance()),
        dispatch(setActiveAsset(BNB_FINE)),
      ]);
    } else if (
      (networkType === networkConstant.networkType.polygon && typeAuth === TYPE_AUTH.METAMASK) ||
      typeAuth === TYPE_AUTH.WALLET_CONNECT_POLYGON
    ) {
      console.log('ulaz 6');

      dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
      dispatch({ type: SET_NETWORK_ID, payload: networkId });
      await Promise.all([
        dispatch(tokenActions.getOmPolygonBalance()),
        dispatch(tokenActions.getOmEthPolygonBalance()),
        dispatch(tokenActions.getGamerPolygonBalance()),
        dispatch(tokenActions.getGamerWethPolygonBalance()),
        dispatch(setActiveAsset(OM_POLYGON)),
      ]);
    } else {
      console.log('ulaz 7', networkType, networkId);

      if (typeAuth === TYPE_AUTH.WALLET_CONNECT) {
        console.log('ulaz 4', networkType);

        //dispatch({ type: SET_NETWORK_TYPE, payload: 'ethereum' });
        //dispatch({ type: SET_NETWORK_ID, payload: 11155111 });

        dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
        dispatch({ type: SET_NETWORK_ID, payload: networkId });

        await Promise.all([dispatch(tokenActions.getOmBalance())]);
      } else if (typeAuth === TYPE_AUTH.TRUST_WALLET) {
        console.log('ulaz 55', networkType);

        //dispatch({ type: SET_NETWORK_TYPE, payload: 'ethereum' });
        //dispatch({ type: SET_NETWORK_ID, payload: 11155111 });

        dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
        dispatch({ type: SET_NETWORK_ID, payload: networkId });

        await Promise.all([dispatch(tokenActions.getOmBalance())]);
      } else if (typeAuth === TYPE_AUTH.OKX_WALLET) {
        console.log('ulaz 555', networkType);

        //dispatch({ type: SET_NETWORK_TYPE, payload: 'ethereum' });
        //dispatch({ type: SET_NETWORK_ID, payload: 11155111 });

        dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
        dispatch({ type: SET_NETWORK_ID, payload: networkId });

        await Promise.all([dispatch(tokenActions.getOmBalance())]);
      } else {
        dispatch({ type: SET_NETWORK_TYPE, payload: networkType });
        dispatch({ type: SET_NETWORK_ID, payload: networkId });

        await Promise.all([
          dispatch(tokenActions.getOmBalance()),
          dispatch(tokenActions.getOmV2Balance()),
          dispatch(tokenActions.getOmV3Balance()),
          dispatch(tokenActions.getPKFBalance()),
          dispatch(tokenActions.getLabsBalance()),
          dispatch(tokenActions.getLabsUniBalance()),
          dispatch(tokenActions.getLabsUniSimpleBalance()),
          dispatch(tokenActions.getL3PBalance()),
          dispatch(tokenActions.getRoyaBalance()),
          dispatch(tokenActions.getFxfBalance()),
          dispatch(tokenActions.getRfuelBalance()),
          dispatch(tokenActions.getUniBalance()),
          dispatch(tokenActions.getUniV2Balance()),
          dispatch(tokenActions.getRoyaUniBalance()),
          dispatch(tokenActions.getKylBalance()),
          dispatch(tokenActions.getRazeBalance()),
          dispatch(tokenActions.getBiteBalance()),
          dispatch(tokenActions.getRazeEthUniBalance()),
          dispatch(tokenActions.getBbankEthUniBalance()),
          dispatch(tokenActions.getBondlyEthUniBalance()),
          dispatch(tokenActions.getBondlyUsdtUniBalance()),
          dispatch(tokenActions.getBondlyEthBalance()),
          dispatch(tokenActions.getBiteEthUniBalance()),
          dispatch(tokenActions.getLabsGroupBalance()),
          dispatch(tokenActions.getBcubeBalance()),
          dispatch(tokenActions.getAlphaBalance()),
          dispatch(tokenActions.getBiteEthIntervalsBalance()),
          dispatch(tokenActions.getBcubeEthUniBalance()),
          dispatch(tokenActions.getAlphaEthUniBalance()),
          dispatch(tokenActions.getSRfuelBalance()),
          // TODO: Uncomment SPWN
          // dispatch(tokenActions.getSpwnBalance()),
          dispatch(tokenActions.getZenDaiUniBalance()),
          dispatch(tokenActions.getZenUsdtOmEthBalance()),
          dispatch(tokenActions.getZenUsdcUniEthBalance()),
          dispatch(tokenActions.getZenUstUniBalance()),
          dispatch(tokenActions.getZenWbtcUniBalance()),
          dispatch(tokenActions.getZenEthUniBalance()),
          dispatch(tokenActions.getCirusBalance()),
          dispatch(tokenActions.getSkyrimBalance()),
        ]);
      }
    }
  }
};

/**
 * Connect to wallet
 */
export const connect = (typeAuth) => async (dispatch) => {
  try {
    console.log('conenct to wallet init', typeAuth);
    await dispatch(authorization(typeAuth));
    localStorage.setItem('connected', typeAuth);
    if (typeAuth === TYPE_AUTH.WALLET_CONNECT) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (typeAuth === TYPE_AUTH.METAMASK) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (typeAuth === TYPE_AUTH.WALLET_LINK) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (typeAuth === TYPE_AUTH.TRUST_WALLET) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else if (typeAuth === TYPE_AUTH.OKX_WALLET) {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }

    //await dispatch(modalActions.hideModal(LOGIN_MODAL));
  } catch (e) {
    const ignoreErrorsTitle = [
      'User closed modal',
      'User denied account authorization',
      'Already processing eth_requestAccounts. Please wait.',
    ];
    if (ignoreErrorsTitle.every((title) => e.message.indexOf(title) < 0)) {
      dispatch(logout());
      toastError(e.message);
    }
    window.location.reload();
    console.log('error', e);
    //throw e;
  }
};
