const toPowerOf = 10 ** 18; // Our ERC20's are 18 decimals
const toPowerOfZen = 10 ** 8; // ZEN is 8 decimals
const toPowerOfDot = 10 ** 10; // DOT is 10 decimals
const toPowerOfKsm = 10 ** 12; // KSM is 12 decimals
const toPowerOfUSDT = 10 ** 6; // USDT is 6 decimals
const toPowerOfAtom = 10 ** 6; // USDT is 6 decimals
const toPowerOfBand = 10 ** 6; // USDT is 6 decimals
const toPowerOfLuna = 10 ** 6; // USDT is 6 decimals
const toPowerOfDpvn = 10 ** 6; // USDT is 6 decimals
const toPowerOfKava = 10 ** 6; // USDT is 6 decimals
const toPowerOfEmoney = 10 ** 6; // USDT is 6 decimals
const toPowerOfMatic = 10 ** 18; // MATIC is 18 decimals
const dappName = 'MLT Staking Platform';

module.exports = {
  DAPP_NAME: dappName,
  toPowerOf,
  toPowerOfZen,
  toPowerOfDot,
  toPowerOfKsm,
  toPowerOfUSDT,
  toPowerOfAtom,
  toPowerOfBand,
  toPowerOfDpvn,
  toPowerOfEmoney,
  toPowerOfKava,
  toPowerOfLuna,
  toPowerOfMatic,
};
