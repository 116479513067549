import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import Footer from './components/footer';
import Header from './components/header';

import * as basicActions from './actions/basic-actions';
import theme from './theme';
import history from './history';
import AccountModal from './components/modals/account-modal';
import LoginModal from './components/modals/login-modal';
import InfoModal from './components/modals/info-modal';
import Toast from './components/toast';
import JoinPoolModal from './components/modals/join-pool-modal';
import PoolResultModal from './components/modals/pool-result-modal';
import PoolClaimModal from './components/modals/pool-claim-modal';
import PoolRoundModal from './components/modals/pool-round-modal';
import PoolProvablyModal from './components/modals/pool-provably-modal';
import ClaimRewardsModal from './components/modals/claim-rewards-modal';
import SupplyModal from './components/modals/supply-modal';
import BorrowModal from './components/modals/borrow-modal';
import EnableCollateralModal from './components/modals/enable-collateral-modal';
import DisableCollateralModal from './components/modals/disable-collateral-modal';
import DistributionOmRewardsModal from './components/modals/distribution-om-rewards-modal';
import DistributionOmSuccessModal from './components/modals/distribution-om-success-modal';
import './grid.scss';
import './app.scss';

class App extends React.Component {
  componentDidMount() {
    const { darkMode, lightMode } = this.props;

    // help remember if user was on dark or light mode
    if (window.localStorage.getItem('mantra-palette') === 'dark') {
      darkMode();
    } else {
      lightMode();
    }

    // Update ui if route changes
    this.unlisten = history.listen(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleDarkLightChange(event) {
    const { darkMode, lightMode } = this.props;
    if (event.target.checked) {
      darkMode();
    } else {
      lightMode();
    }
  }

  colors() {
    const { dark } = this.props;
    if (dark) {
      return theme.palette.dark;
    }
    return theme.palette.light;
  }

  render() {
    const { dark, light, children } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Header />
          <div
            style={{
              display: 'flex',
              minHeight: '100vh',
            }}
          >
            {children}
          </div>
          <Footer
            isDark={dark}
            colors={this.colors()}
            handleDarkLightChange={(e) => this.handleDarkLightChange(e)}
          />
          <InfoModal />
          <PoolClaimModal />
          <JoinPoolModal />
          <PoolResultModal />
          <PoolRoundModal />
          <PoolProvablyModal />
          <AccountModal />
          <LoginModal show={false} global={{ dark, light }} />
          <ClaimRewardsModal />
          <SupplyModal />
          <BorrowModal />
          <EnableCollateralModal />
          <DisableCollateralModal />
          <DistributionOmRewardsModal />
          <DistributionOmSuccessModal />
          <Toast />
        </Router>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element.isRequired,
  dark: PropTypes.bool.isRequired,
  light: PropTypes.bool.isRequired,
  darkMode: PropTypes.func.isRequired,
  lightMode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dark: state.basic.dark,
  light: state.basic.light,
});

export default compose(
  connect(mapStateToProps, {
    darkMode: basicActions.darkMode,
    lightMode: basicActions.lightMode,
  }),
)(App);
