import BN from 'bignumber.js';

import OmIcon from '../assets/images/tokens/om.svg';
import OmEthIcon from '../assets/images/tokens/om-eth.svg';
import OmIconBlack from '../assets/images/tokens/om-v2.svg';
import PKFIcon from '../assets/images/tokens/PKF.png';
import L3PIcon from '../assets/images/L3P.svg';
import RFUELIcon from '../assets/images/rfuel-icon.svg';
import UniIcon from '../assets/images/uni-icon.png';
import DotIcon from '../assets/images/DOT.svg';
import KsmIcon from '../assets/images/KSM.svg';
import RoyaUniIcon from '../assets/images/tokens/roya-uni.svg';
import RoyaIcon from '../assets/images/tokens/roya-bordered.svg';
import FxfIcon from '../assets/images/tokens/fxf-icon.svg';
import LabsUniIcon from '../assets/images/labs-uni-icon.svg';
import LabsIcon from '../assets/images/labs-icon.svg';
import KylIcon from '../assets/images/KYL.svg';
import BnbFineIcon from '../assets/images/bnb-fine.svg';
import BnbOm from '../assets/images/bnb-om.svg';
import RazeEth from '../assets/images/raze-eth.png';
import BbankEth from '../assets/images/bbank-eth.png';
import BondlyEth from '../assets/images/bondly-eth.svg';
import BondlyUsdt from '../assets/images/bondly-usdt.svg';
import RosnIcon from '../assets/images/rosn.svg';
import MltIcon from '../assets/images/mlt.svg';
import RazeIcon from '../assets/images/RAZE.png';
import BiteIcon from '../assets/images/BITE.svg';
import CirusIcon from '../assets/images/Cirus.svg';
import SkyrimIcon from '../assets/images/Skyrim.svg';
import BcubeIcon from '../assets/images/BCUBE.svg';
import SpwnIcon from '../assets/images/SPWN.svg';
import BcubeEthIcon from '../assets/images/BCUBE_ETH.svg';
import AlphaEthIcon from '../assets/images/ALPHA_ETH.svg';
import BiteEthIcon from '../assets/images/BITE_ETH.svg';
import ZenUsdtIcon from '../assets/images/ZENUSDT.svg';
import ZenUsdcIcon from '../assets/images/ZENUSDC.svg';
import BondlyIcon from '../assets/images/bondly-icon.svg';
import BnbMistIcon from '../assets/images/bnb-mist.png';
import BnbCbdIcon from '../assets/images/bnb-cbd.svg';
import BnbBbankIcon from '../assets/images/bnb-bbank.png';
import BnbMltIcon from '../assets/images/bnb-mlt.svg';
import BnbRosnIcon from '../assets/images/bnb-rosn.svg';
import BondlyWbnbIcon from '../assets/images/bondly-wbnb.svg';
import AlphaIcon from '../assets/images/ALPHA.svg';
import ZenDaiIcon from '../assets/images/ZEN_DAI.svg';
import ZenUstIcon from '../assets/images/ZEN_UST.svg';
import ZenWBTCIcon from '../assets/images/ZEN_WBTC.svg';
import ZenEthIcon from '../assets/images/ZEN_ETH.svg';
import GamerIcon from '../assets/images/GAMER.png';
import GamerWethIcon from '../assets/images/GAMER_WETH.png';
import StfiWbnbIcon from '../assets/images/STFI_WBNB.png';
import StfiIcon from '../assets/images/STFI.png';
import L3pWbnbIcon from '../assets/images/L3P_WBNB.png';
import FactrIcon from '../assets/images/FACTR.png';
import FactrbnbIcon from '../assets/images/FACTR_WBNB.png';
import GamerWbnbIcon from '../assets/images/GAMER_WBNB.png';

// import OmBscIcon from '../assets/images/om-bsc.svg';
// import SRfuelIcon from '../assets/images/srfuel.svg';

export const ETH = 'ETH';
export const OM = 'OM';
export const sOM = 'sOM';
export const OM_V2 = 'OM_V2';
export const OM_V3 = 'OM_V3';
export const OM_BSC = 'OM_BSC';
export const OM_POLYGON = 'OM_POLYGON';
export const OM_ETH_POLYGON = 'OM_ETH_POLYGON';
export const GAMER_POLYGON = 'GAMER_POLYGON';
export const GAMER_WETH_POLYGON = 'GAMER_WETH_POLYGON';
export const BNB_FINE = 'BNB_FINE';
export const BNB_MIST = 'BNB_MIST';
export const BNB_CBD = 'BNB_CBD';
export const BNB_BBANK = 'BNB_BBANK';
export const BNB_ROSN = 'BNB_ROSN';
export const BNB_OM = 'BNB_OM';
export const BNB = 'BNB';
export const RAZE = 'RAZE';
export const SRFUEL = 'sRFUEL';
export const BITE = 'BITE';
export const CIRUS = 'CIRUS';
export const SKYRIM = 'SKYRIM';
export const BCUBE = 'BCUBE';
export const SPWN = 'SPWN';
export const BONDLY = 'BONDLY';
export const ALPHA = 'IMPACT';
export const KYL = 'KYL';
export const PKF = 'PKF';
export const L3P = 'L3P';
export const RFUEL = 'RFUEL';
export const UNI = 'UNI';
export const DOT = 'DOT';
export const KSM = 'KSM';
export const ROYA_UNI = 'ROYA_UNI';
export const RAZE_ETH_UNI = 'RAZE_ETH_UNI';
export const BBANK_ETH_UNI = 'BBANK_ETH_UNI';
export const BONDLY_ETH_UNI = 'BONDLY_ETH_UNI';
export const BCUBE_ETH_UNI = 'BCUBE_ETH_UNI';
export const ALPHA_ETH_UNI = 'IMPACT_ETH_UNI';
export const BITE_ETH_UNI = 'BITE_ETH_UNI';
export const BITE_ETH_INTERVALS = 'BITE_ETH_INTERVALS';
export const BONDLY_USDT_UNI = 'BONDLY_USDT_UNI';
export const ROYA = 'ROYA';
export const FXF = 'FXF';
export const LABS = 'LABS';
export const ROSN = 'ROSN';
export const WBNB = 'WBNB';
export const WETH = 'WETH';
export const BONDLY_WBNB = 'BONDLY_WBNB';
export const BONDLY_ETH = 'BONDLY_ETH';
export const USDT = 'USDT';
export const USDC = 'USDC';
export const DAI = 'DAI';
export const UST = 'UST';
export const WBTC = 'WBTC';
export const BONDLY_BSC = 'BONDLY_BSC';
export const LABS_GROUP = 'LABS';
export const MATIC = 'MATIC';
export const MLT = 'MLT';
export const ZENDAI = 'ZENDAI';
export const ZEN_DAI_UNI = 'ZEN_DAI_UNI';
export const ZENUSDT = 'ZENUSDT';
export const ZENUSDC = 'ZENUSDC';
export const ZENUSDT_OM_ETH = 'ZENUSDT_OM_ETH';
export const ZENUST = 'ZENUST';
export const ZEN_UST_UNI = 'ZEN_UST_UNI';
export const ZENUSDC_UNI_ETH = 'ZENUSDC_UNI_ETH';
export const ZENETH = 'ZENETH';
export const ZEN_ETH_UNI = 'ZEN_ETH_UNI';
export const ZENWBTC = 'ZENWBTC';
export const ZEN_WBTC_UNI = 'ZEN_WBTC_UNI';
export const GAMER = 'GAMER';
export const STFI = 'STFI';
export const STFI_WBNB_UNI = 'STFI_WBNB_UNI';
export const L3P_WBNB_UNI = 'L3P_WBNB_UNI';
export const FACTR = 'FACTR';
export const FACTR_WBNB_UNI = 'FACTR_WBNB_UNI';
export const GAMER_WBNB_UNI = 'GAMER_WBNB_UNI';
export const ASVA = 'ASVA';
export const BUSD = 'BUSD';
export const UMAD = 'UMAD';

export const HELI = 'HELI[0x]';

export const WMATIC = 'WMATIC';

export const ATOM = 'ATOM';
export const BAND = 'BAND';
export const LUNA = 'LUNA';
export const EMONEY = 'EMONEY';
export const DPVN = 'DPVN';
export const KAVA = 'KAVA';

export const BINANCE = 'binance';
export const POLYGON = 'polygon';

export const AVAILABLE_TOKENS = [OM, RFUEL, UNI, DOT, KSM];
export const UNI_V2 = 'UNI_V2';

export const LABS_UNI = 'LABS_UNI';
export const LABS_UNI_SIMPLE = 'LABS_UNI_SIMPLE';
export const BNB_MLT = 'BNB_MLT';

export const getNetworkTitle = (type) => {
  switch (type) {
    case BINANCE:
      return 'Binance Smart Chain';
    case POLYGON:
      return 'Polygon';
    default:
      break;
  }
};

export const TOKEN_OPTIONS = {
  [OM]: {
    title: 'OM V1',
    titleRewardToken: 'OM V1',
    icon: OmIcon,
    initialCoeff: 0.8888,
  },
  [OM_V2]: {
    title: 'OM V2',
    titleRewardToken: 'OM V2',
    icon: OmIconBlack,
    initialCoeff: 0.8888,
  },
  [OM_V3]: {
    title: 'OM',
    titleRewardToken: 'OM',
    icon: OmIconBlack,
    initialCoeff: 0.8888,
  },
  [POLYGON]: {
    title: 'MATIC',
    titleRewardToken: 'POLYGON',
    icon: OmIcon,
    initialCoeff: 0.8888,
  },
  [BNB_FINE]: {
    title: 'FINE/BNB',
    titleRewardToken: 'FINE',
    icon: BnbFineIcon,
  },
  [BNB_MIST]: {
    title: 'MIST/BNB',
    titleRewardToken: 'MIST',
    icon: BnbMistIcon,
  },
  [BNB_CBD]: {
    title: 'CBD/BNB',
    titleRewardToken: 'CBD',
    icon: BnbCbdIcon,
  },
  [BNB_BBANK]: {
    title: 'BBANK/BNB',
    titleRewardToken: 'BBANK',
    icon: BnbBbankIcon,
  },
  [BNB_MLT]: {
    title: 'MLT/BNB',
    titleRewardToken: 'MLT',
    icon: BnbMltIcon,
  },
  [BNB_ROSN]: {
    title: 'ROSN/BNB',
    titleRewardToken: 'ROSN',
    icon: BnbRosnIcon,
  },
  [BONDLY_WBNB]: {
    title: 'BONDLY/WBNB',
    titleRewardToken: 'BONDLY',
    icon: BondlyWbnbIcon,
  },
  [BNB_OM]: {
    title: 'OM/BNB',
    titleRewardToken: 'OM',
    icon: BnbOm,
  },
  [RAZE]: {
    title: 'RAZE',
    titleRewardToken: 'RAZE',
    icon: RazeIcon,
    initialCoeff: 0.8888,
  },
  [SRFUEL]: {
    title: 'RFUEL',
    titleRewardToken: 'RFUEL',
    icon: RFUELIcon,
    initialCoeff: 0.8888,
  },
  [BITE]: {
    title: 'BITE',
    titleRewardToken: 'BITE',
    icon: BiteIcon,
    initialCoeff: 0.8888,
  },
  [CIRUS]: {
    title: 'CIRUS',
    titleRewardToken: 'CIRUS',
    icon: CirusIcon,
    initialCoeff: 0.8888,
  },
  [SKYRIM]: {
    title: 'SKYRIM',
    titleRewardToken: 'SKYRIM',
    icon: SkyrimIcon,
    initialCoeff: 0.8888,
  },
  [BCUBE]: {
    title: 'BCUBE',
    titleRewardToken: 'BCUBE',
    icon: BcubeIcon,
    initialCoeff: 0.8888,
  },
  [SPWN]: {
    title: 'SPWN',
    titleRewardToken: 'SPWN',
    icon: SpwnIcon,
    initialCoeff: 0.8888,
  },
  [ALPHA]: {
    title: 'IMPACT',
    titleRewardToken: 'IMPACT',
    icon: AlphaIcon,
    initialCoeff: 0.8888,
  },
  [BONDLY_ETH]: {
    title: 'BONDLY',
    titleRewardToken: 'BONDLY',
    icon: BondlyIcon,
    initialCoeff: 0.8888,
  },
  [ROSN]: {
    title: 'ROSN',
    titleRewardToken: 'ROSN',
    icon: RosnIcon,
    initialCoeff: 0.8888,
  },
  [MLT]: {
    title: 'MLT',
    titleRewardToken: 'MLT',
    icon: MltIcon,
    initialCoeff: 0.8888,
  },
  [OM_BSC]: {
    title: 'OM',
    titleRewardToken: 'OM',
    icon: OmIcon,
    initialCoeff: 0.8888,
  },
  [OM_POLYGON]: {
    title: 'OM',
    titleRewardToken: 'OM',
    icon: OmIcon,
    initialCoeff: 0.8888,
  },
  [GAMER_POLYGON]: {
    title: 'GAMER',
    titleRewardToken: 'GAMER',
    icon: GamerIcon,
    initialCoeff: 0.8888,
  },
  [GAMER_WETH_POLYGON]: {
    title: 'GAMER/WETH',
    titleRewardToken: 'GAMER',
    icon: GamerWethIcon,
    initialCoeff: 0.8888,
  },
  [OM_ETH_POLYGON]: {
    title: 'QUICK OM/ETH',
    titleRewardToken: 'OM',
    icon: OmEthIcon,
    initialCoeff: 0.8888,
  },
  [BONDLY_BSC]: {
    title: 'BONDLY',
    titleRewardToken: 'BONDLY',
    icon: BondlyIcon,
    initialCoeff: 0.8888,
  },
  [KYL]: {
    title: 'KYL',
    titleRewardToken: 'KYL',
    icon: KylIcon,
    initialCoeff: 0.8888,
  },
  [PKF]: {
    title: 'PKF',
    titleRewardToken: 'PKF',
    icon: PKFIcon,
    initialCoeff: 0.8888,
  },
  [L3P]: {
    title: 'L3P',
    titleRewardToken: 'L3P',
    icon: L3PIcon,
    initialCoeff: 0.8888,
  },
  [RFUEL]: {
    title: 'RFUEL',
    titleRewardToken: 'RFUEL',
    icon: RFUELIcon,
    initialCoeff: 0.5,
  },
  [UNI]: {
    title: 'UNI OM V1/ETH',
    titleRewardToken: 'OM V1',
    icon: UniIcon,
  },
  [UNI_V2]: {
    title: 'UNI OM V2/ETH',
    titleRewardToken: 'OM V2',
    icon: UniIcon,
  },
  [LABS_UNI]: {
    title: 'UNI LABS/ETH',
    titleRewardToken: 'LABS',
    icon: LabsUniIcon,
  },
  [DOT]: {
    title: 'DOT',
    titleRewardToken: 'DOT',
    icon: DotIcon,
  },
  [KSM]: {
    title: 'KSM',
    titleRewardToken: 'KSM',
    icon: KsmIcon,
  },
  [ROYA_UNI]: {
    title: 'UNI ROYA/ETH',
    titleRewardToken: 'ROYA',
    icon: RoyaUniIcon,
  },
  [ROYA]: {
    title: 'ROYA',
    titleRewardToken: 'ROYA',
    icon: RoyaIcon,
    initialCoeff: 0.8888,
  },
  [FXF]: {
    title: 'FXF',
    titleRewardToken: 'FXF',
    icon: FxfIcon,
    initialCoeff: 0.8888,
  },
  [LABS]: {
    title: 'LABS',
    titleRewardToken: 'LABS',
    icon: LabsIcon,
  },
  [LABS_GROUP]: {
    title: 'LABS',
    titleRewardToken: 'LABS',
    icon: LabsIcon,
  },
  [RAZE_ETH_UNI]: {
    title: 'RAZE/ETH',
    titleRewardToken: 'RAZE',
    icon: RazeEth,
  },
  [BBANK_ETH_UNI]: {
    title: 'BBANK/ETH',
    titleRewardToken: 'BBANK',
    icon: BbankEth,
  },
  [BONDLY_ETH_UNI]: {
    title: 'BONDLY/ETH',
    titleRewardToken: 'BONDLY',
    icon: BondlyEth,
  },
  [BCUBE_ETH_UNI]: {
    title: 'BCUBE/ETH',
    titleRewardToken: 'BCUBE',
    icon: BcubeEthIcon,
  },
  [ALPHA_ETH_UNI]: {
    title: 'IMPACT/ETH',
    titleRewardToken: 'IMPACT',
    icon: AlphaEthIcon,
  },
  [BITE_ETH_UNI]: {
    title: 'BITE/ETH',
    titleRewardToken: 'BITE',
    icon: BiteEthIcon,
  },
  [ZENUSDT_OM_ETH]: {
    title: 'zenUSDT',
    titleRewardToken: 'OM',
    icon: ZenUsdtIcon,
  },
  [ZENUSDC_UNI_ETH]: {
    title: 'zenUSDC',
    titleRewardToken: 'OM',
    icon: ZenUsdcIcon,
  },
  [BITE_ETH_INTERVALS]: {
    title: 'BITE/ETH',
    titleRewardToken: 'BITE',
    icon: BiteEthIcon,
  },
  [BONDLY_USDT_UNI]: {
    title: 'BONDLY/USDT',
    titleRewardToken: 'BONDLY',
    icon: BondlyUsdt,
  },
  [ZEN_DAI_UNI]: {
    title: 'zenDAI',
    titleRewardToken: 'OM',
    icon: ZenDaiIcon,
  },
  [ZEN_UST_UNI]: {
    title: 'zenUST',
    titleRewardToken: 'OM',
    icon: ZenUstIcon,
  },
  [ZEN_WBTC_UNI]: {
    title: 'zenWBTC',
    titleRewardToken: 'OM',
    icon: ZenWBTCIcon,
  },
  [ZEN_ETH_UNI]: {
    title: 'zenETH',
    titleRewardToken: 'OM',
    icon: ZenEthIcon,
  },
  [STFI_WBNB_UNI]: {
    title: 'STFI/WBNB',
    titleRewardToken: 'STFI/WBNB',
    icon: StfiWbnbIcon,
  },
  [STFI]: {
    title: 'STFI',
    titleRewardToken: 'STFI',
    icon: StfiIcon,
  },
  [L3P_WBNB_UNI]: {
    title: 'L3P/BNB',
    titleRewardToken: 'L3P',
    icon: L3pWbnbIcon,
  },
  [FACTR_WBNB_UNI]: {
    title: 'FACTR/BNB',
    titleRewardToken: 'FACTR',
    icon: FactrbnbIcon,
  },
  [FACTR]: {
    title: 'FACTR',
    titleRewardToken: 'FACTR',
    icon: FactrIcon,
  },
  [GAMER_WBNB_UNI]: {
    title: 'GAMER/WBNB',
    titleRewardToken: 'GAMER',
    icon: GamerWbnbIcon,
  },
};

export const INFURA_NETWORK = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
};

export const OM_STAKING_MIGRATE_STATUS = {
  HIDE_POOL: 'hide_pool',
  NEED_RUN_MIGRATE: 'need_run_migrate',
  MIGRATED_FIRST_STEP: 'migrated_first_step',
  MIGRATE_FINISHED: 'migrate_finished',
};

export const COUNT_BLOCKS_IN_ROUND = Math.ceil((60 * 10) / 15);

export const AVERAGE_BLOCK_TIME = 12;
export const AVERAGE_BLOCK_TIME_BNB = 3.1;
export const AMOUNT_BLOCKS_IN_DAY = Number.parseInt((60 * 60 * 24) / AVERAGE_BLOCK_TIME, 10);

// Delegator rewards - DOT/KSM
export const SHORT_CLAIM_PERIOD = 30;
export const LONG_CLAIM_PERIOD = 180;

// Airgrabs - Polygon/OM
export const SHORT_CLAIM_PERIOD_AIRGRAB = 15;
export const LONG_CLAIM_PERIOD_AIRGRAB = 45;

export const HOUR = 60 * 60 * 1000;
export const DAY = 24 * HOUR;
export const SHORT_CLAIM_PENALTY = 0.8;

export const CHAIN_NAMES = {
  KUSAMA: 'KUSAMA',
};

export const MERKLE_AIRDROP_NAMES = {
  MATIC: 'MATIC',
};

export const KUSAMA_UNAWAILABLE_CLAIM_PERIOD = 6 * HOUR;

export const KUSAMA_API_EXTRINSICS_URL = 'https://Kusama.subscan.io/api/scan/extrinsics';
export const EXP_DECIMALS = 18;
export const UINT_256_MAX_BN = new BN(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
).toFixed(0);

export const STAKE_INTERVAL_STATUS = {
  COMPLETED: 'COMPLETED',
  CURRENT: 'CURRENT',
  NEXT: 'NEXT',
};
