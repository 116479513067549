/* eslint-disable max-len */
import React from 'react';

export const CopyIcon = (
  <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.7138 3.67969H2.60623C1.7186 3.67969 0.999023 4.39926 0.999023 5.2869V14.3944C0.999023 15.2821
      1.7186 16.0016 2.60623 16.0016H11.7138C12.6014 16.0016 13.321 15.2821 13.321 14.3944V5.2869C13.321 4.39926
      12.6014 3.67969 11.7138 3.67969ZM12.2495 14.3945C12.2495 14.6903 12.0096 14.9302 11.7138 14.9302H2.60623C2.31034
      14.9302 2.07049 14.6903 2.07049 14.3945V5.2869C2.07049 4.99101 2.31034 4.75115 2.60623 4.75115H11.7138C12.0096
      4.75115 12.2495 4.99101 12.2495 5.2869V14.3945Z'
      fill='#9AA0AD'
      stroke='#9AA0AD'
      strokeWidth='0.461562'
    />
    <path
      d='M14.3928 1H4.74955C3.86191 1 3.14233 1.71957 3.14233 2.60721C3.14233 2.9031 3.38219 3.14296 3.67808 3.14296C3.97397
      3.14296 4.2138 2.9031 4.2138 2.60721C4.2138 2.31132 4.45366 2.07146 4.74955 2.07146H14.3928C14.6887 2.07146 14.9286
      2.31132 14.9286 2.60721V12.2505C14.9286 12.5464 14.6887 12.7862 14.3928 12.7862C14.0969 12.7862 13.8571
      13.0261 13.8571 13.322C13.8571 13.6179 14.0969 13.8577 14.3928 13.8577C15.2805 13.8577 16 13.1381 16 12.2505V2.60721C16
      1.71957 15.2805 1 14.3928 1Z'
      fill='#9AA0AD'
      stroke='#9AA0AD'
      strokeWidth='0.461562'
    />
  </svg>
);

export const ViewIcon = (
  <svg width='25' height='15' viewBox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M24.1166 7.04174C23.9064 6.75416 18.8973 0 12.4997 0C6.1021 0 1.09273 6.75416 0.882731
      7.04147C0.683621 7.3143 0.683621 7.68435 0.882731 7.95718C1.09273 8.24477 6.1021 14.9989 12.4997
      14.9989C18.8973 14.9989 23.9064 8.24472 24.1166 7.95741C24.316 7.68462 24.316 7.3143 24.1166
      7.04174ZM12.4997 13.4473C7.78718 13.4473 3.70566 8.96445 2.49744 7.49893C3.7041 6.03213 7.77707
      1.5516 12.4997 1.5516C17.212 1.5516 21.2932 6.03369 22.5019 7.49999C21.2953 8.96675 17.2223 13.4473 12.4997 13.4473Z'
      fill='#9AA0AD'
    />
    <path
      d='M12.4996 2.84375C9.93294 2.84375 7.84473 4.93197 7.84473
      7.49861C7.84473 10.0653 9.93294 12.1535 12.4996 12.1535C15.0662 12.1535 17.1544
      10.0653 17.1544 7.49861C17.1544 4.93197 15.0662 2.84375 12.4996 2.84375ZM12.4996
      10.6018C10.7884 10.6018 9.39638 9.20975 9.39638 7.49861C9.39638 5.78747 10.7884 4.3954
      12.4996 4.3954C14.2107 4.3954 15.6028 5.78747 15.6028 7.49861C15.6028 9.20975 14.2108
      10.6018 12.4996 10.6018Z'
      fill='#9AA0AD'
    />
  </svg>
);

export const ButtonArrowIcon = (
  <svg width='6' height='12' viewBox='0 0 6 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.01496 0.999512L4.60095 5.68292L1.00026 10.6014'
      stroke='#630e17'
      strokeWidth='1.92037'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const BuyOmButtonIcon = (
  <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.82013e-05 10.0466C7.82013e-05 14.4332 3.53424 17.9892 7.89384 17.9892C12.2534 17.9892 15.7876 14.4332 15.7876 10.0466C15.7876 5.66003
      12.2534 2.104 7.89384 2.104C3.53424 2.104 7.82013e-05 5.66003 7.82013e-05 10.0466Z'
      fill='#630e17'
    />
    <path
      d='M14.638 2.1564C14.638 3.34735 15.5975 4.3128 16.7811 4.3128C17.9648 4.3128 18.9243 3.34735 18.9243
      2.1564C18.9243 0.965453 17.9648 0 16.7811 0C15.5975 0 14.638 0.965453 14.638 2.1564Z'
      fill='#630e17'
    />
  </svg>
);

export const BackArrow = (
  <svg width='28' height='16' viewBox='0 0 28 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27 9C27.5523 9 28 8.55228 28 8C28 7.44772 27.5523 7 27 7L27 9ZM0.292892 7.29289C-0.0976314
      7.68342 -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616
      8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159
      1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292892
      7.29289ZM27 7L1 7L1 9L27 9L27 7Z'
      fill='white'
    />
  </svg>
);

export const BackArrowIcon = (
  <svg width='28' height='16' viewBox='0 0 28 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M27 9C27.5523 9 28 8.55228 28 8C28 7.44772 27.5523 7 27 7L27 9ZM0.292892 7.29289C-0.0976314 7.68342
      -0.0976315 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159
      14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107
      0.928931C7.68054 0.538407 7.04738 0.538407 6.65686 0.928931L0.292892 7.29289ZM27 7L1 7L1 9L27 9L27 7Z'
      fill='#201f1e'
    />
  </svg>
);

export const BackArrowLargeIcon = (
  <svg width='53' height='30' viewBox='0 0 53 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M43.4751 16.6C44.3588 16.6 45.0751 15.8837 45.0751 15C45.0751 14.1163 44.3588 13.4 43.4751 13.4L43.4751 16.6ZM0.743725 13.8686C0.118885 14.4935 0.118885 15.5065 0.743725 16.1314L10.9261 26.3137C11.5509 26.9385 12.564 26.9385 13.1888 26.3137C13.8136 25.6889 13.8136 24.6758 13.1888 24.051L4.13784 15L13.1888 5.94903C13.8136 5.32419 13.8136 4.31113 13.1888 3.68629C12.564 3.06145 11.5509 3.06145 10.9261 3.68629L0.743725 13.8686ZM43.4751 13.4L1.8751 13.4L1.8751 16.6L43.4751 16.6L43.4751 13.4Z'
      fill='#B4182C'
    />
  </svg>
);

export const StakeMoreArrow = (
  <svg width='23' height='50' viewBox='0 0 23 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12.5645 2C12.5645 1.17157 11.8929 0.5 11.0645 0.5C10.236 0.5 9.56445 1.17157
      9.56445 2L12.5645 2ZM10.0038 49.0607C10.5896 49.6464 11.5393 49.6464 12.1251
      49.0607L21.6711 39.5147C22.2568 38.9289 22.2568 37.9792 21.6711 37.3934C21.0853
      36.8076 20.1355 36.8076 19.5497 37.3934L11.0645 45.8787L2.57917 37.3934C1.99339
      36.8076 1.04364 36.8076 0.457853 37.3934C-0.127934 37.9792 -0.127934 38.9289
      0.457853 39.5147L10.0038 49.0607ZM9.56445 2L9.56446 48L12.5645 48L12.5645 2L9.56445 2Z'
      fill='white'
    />
  </svg>
);

export const LeaveIcon = (
  <svg width='19' height='17' viewBox='0 0 19 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M15.9041 8.81706H15.1692C15.0619 8.81706 14.9738 8.85145 14.9053 8.92053C14.8362 8.98921 14.8019 9.07724 14.8019 9.18444V12.8583C14.8019 13.3634 14.6221 13.7958 14.2624 14.1556C13.9027 14.5153 13.4702 14.695 12.965 14.695H3.41285C2.9077 14.695 2.47522 14.5153 2.11544 14.1556C1.75575 13.7958 1.57592 13.3635 1.57592 12.8583V3.30624C1.57592 2.8011 1.75571 2.36878 2.11544 2.00904C2.47522 1.64926 2.9077 1.46948 3.41285 1.46948L9.10739 1.46947C9.21471 1.46947 9.30278 1.43493 9.37158 1.36612C9.44038 1.29724 9.47477 1.2093 9.47477 1.10205V0.367177C9.47477 0.260132 9.44038 0.172027 9.37158 0.103265C9.30278 0.0343813 9.21471 0 9.10739 0L3.41285 6.09308e-06C2.50212 6.09308e-06 1.7233 0.323391 1.07644 0.970122C0.429831 1.61681 0.106445 2.39564 0.106445 3.30636V12.8584C0.106445 13.7691 0.429831 14.548 1.07648 15.1945C1.72334 15.8413 2.50216 16.1649 3.41289 16.1649H12.965C13.8757 16.1649 14.6546 15.8413 15.3014 15.1945C15.9482 14.548 16.2716 13.7691 16.2716 12.8584V9.18472C16.2716 9.0774 16.2371 8.98933 16.1681 8.92053C16.0992 8.85145 16.0111 8.81706 15.9041 8.81706Z'
      fill='#B4182C'
    />
    <path
      d='M6.81687 10.5688C6.56579 10.8199 6.56579 11.227 6.81687 11.4781C7.06795 11.7291 7.47502 11.7291 7.7261 11.4781L6.81687 10.5688ZM18.2012 0.736647C18.2012 0.381569 17.9134 0.0937222 17.5583 0.0937223L11.772 0.0937224C11.4169 0.0937224 11.129 0.38157 11.129 0.736647C11.129 1.09172 11.4169 1.37957 11.772 1.37957L16.9154 1.37957L16.9154 6.52297C16.9154 6.87804 17.2032 7.16589 17.5583 7.16589C17.9134 7.16589 18.2012 6.87804 18.2012 6.52297L18.2012 0.736647ZM7.7261 11.4781L18.0129 1.19126L17.1037 0.282031L6.81687 10.5688L7.7261 11.4781Z'
      fill='#B4182C'
    />
  </svg>
);

export const RefreshIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.3 13.4296C20.048 13.351 19.7752 13.3753 19.5411 13.4971C19.3069 13.6188 19.1304 13.8282 19.05 14.0796C18.5776 15.524 17.6566 16.7801 16.421 17.665C15.1854 18.5499 13.6997 19.0174 12.18 18.9996C10.3003 19.0209 8.48894 18.296 7.14297 16.9838C5.79701 15.6716 5.02635 13.8792 5 11.9996C5.02635 10.12 5.79701 8.32755 7.14297 7.01533C8.48894 5.70311 10.3003 4.9782 12.18 4.99957C13.8776 4.99547 15.5229 5.58638 16.83 6.66957L14.66 6.30957C14.5299 6.28817 14.3969 6.29269 14.2686 6.32287C14.1403 6.35306 14.0193 6.4083 13.9124 6.48544C13.8055 6.56257 13.715 6.66007 13.6459 6.77233C13.5768 6.88459 13.5307 7.0094 13.51 7.13957C13.4886 7.26962 13.4931 7.40262 13.5233 7.53092C13.5535 7.65922 13.6087 7.78029 13.6859 7.88716C13.763 7.99403 13.8605 8.08461 13.9728 8.15367C14.085 8.22273 14.2098 8.26891 14.34 8.28957L18.58 8.98957H18.75C18.866 8.98943 18.981 8.96913 19.09 8.92957C19.1266 8.91567 19.1605 8.89535 19.19 8.86957C19.2617 8.84291 19.3291 8.80585 19.39 8.75957L19.48 8.64957C19.48 8.59957 19.57 8.55957 19.61 8.49957C19.65 8.43957 19.61 8.39957 19.66 8.35957C19.6876 8.30133 19.711 8.24118 19.73 8.17957L20.48 4.17957C20.505 4.04825 20.5038 3.9133 20.4766 3.78242C20.4494 3.65155 20.3967 3.52731 20.3215 3.41681C20.2462 3.30631 20.15 3.21171 20.0382 3.1384C19.9264 3.06509 19.8013 3.01452 19.67 2.98957C19.5387 2.96462 19.4037 2.96578 19.2729 2.99298C19.142 3.02018 19.0177 3.0729 18.9072 3.14811C18.6841 3.30002 18.5304 3.53435 18.48 3.79957L18.21 5.24957C16.536 3.79881 14.3952 3 12.18 2.99957C9.76993 2.97824 7.44984 3.91387 5.7288 5.60115C4.00777 7.28843 3.02639 9.58955 3 11.9996C3.02639 14.4096 4.00777 16.7107 5.7288 18.398C7.44984 20.0853 9.76993 21.0209 12.18 20.9996C14.1331 21.0296 16.0443 20.4317 17.6321 19.2939C19.2199 18.1562 20.4005 16.5387 21 14.6796C21.0379 14.5515 21.0498 14.4172 21.035 14.2845C21.0202 14.1518 20.9789 14.0234 20.9137 13.9069C20.8485 13.7904 20.7605 13.6881 20.6551 13.6061C20.5497 13.5242 20.429 13.4641 20.3 13.4296Z'
      fill='white'
    />
  </svg>
);

export const SearchIcon = (
  <svg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18.3834 15.5492L14.8833 12.0351C16.6123 8.9983 16.1827 5.05834 13.5931 2.47108C10.4876 -0.631637 5.43458 -0.631637 2.32912 2.47108C-0.776344 5.5738 -0.776401 10.6222 2.32912 13.7249C4.91866 16.3122 8.86223 16.7414 11.9017 15.0139L15.4018 18.5281C16.2238 19.3494 17.5615 19.3493 18.3834 18.5281C19.2055 17.7069 19.2055 16.3705 18.3834 15.5492ZM12.0066 12.1399C9.77589 14.3687 6.14631 14.3687 3.91553 12.1399C1.6848 9.91119 1.6848 6.28484 3.91553 4.05612C6.14631 1.8274 9.77589 1.8274 12.0066 4.05612C14.2374 6.28484 14.2373 9.91125 12.0066 12.1399Z'
      fill='#A5ADBD'
    />
  </svg>
);
